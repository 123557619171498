import { useAddressValidator } from '@hofy/address';
import {
    BillingEntityPayload,
    ContactPayload,
    emptyBillingEntityPayload,
    emptyContactPayload,
    useBillingEntityQuery,
} from '@hofy/api-admin';
import { addressToPayloadWithDefault, AddressType, emptyAddressPayload } from '@hofy/api-shared';
import { Country } from '@hofy/global';
import {
    isBlank,
    isRequired,
    isValidEmailAddress,
    isValidPhone,
    isValidRegex,
    useForm,
    useFormArrayField,
    validateArrayField,
    validator,
} from '@hofy/ui';

interface BillingEntityMutation {
    mutate(formState: BillingEntityPayload): void;
    isPending: boolean;
    isError: boolean;
}

const companyNumberRegex: Partial<Record<Country, RegExp>> = {
    [Country.UnitedStates]: /^\d{2}-\d{7}$/,
    [Country.UnitedKingdom]: /^\d{8}$/,
};

export const useBillingEntityForm = (
    mutation: BillingEntityMutation,
    organizationId: number | null,
    billingEntityId: number | undefined,
) => {
    const { billingEntity } = useBillingEntityQuery(billingEntityId);
    const validateAddress = useAddressValidator();
    const requireContactIfTwo =
        (isTwo: boolean, message: string) => (v: string | null, p: ContactPayload) => {
            if (!p.isDefault || !isTwo) {
                return undefined;
            }
            return isBlank(v) ? message : undefined;
        };

    const requireRegistrationNumberFormat =
        (message: string) => (v: string | null, p: BillingEntityPayload) => {
            if (!companyNumberRegex[p.billingAddress.country]) {
                return undefined;
            }
            return isValidRegex<BillingEntityPayload, 'registeredCompanyNumber'>(
                companyNumberRegex[p.billingAddress.country],
                message,
            )(v, p);
        };
    const contactsRequired = (message: string) => (v: Country[], p: BillingEntityPayload) => {
        if (!p.organizationId || p.isDefault) {
            return undefined;
        }
        return v.length > 0 ? undefined : message;
    };
    const emptyAddress = emptyAddressPayload(AddressType.Entity);

    const form = useForm<BillingEntityPayload>({
        initial: billingEntity
            ? {
                  id: billingEntity.idDeprecated,
                  currency: billingEntity.currency,
                  paymentTerms: billingEntity.paymentTerms,
                  name: billingEntity.name,
                  vatNumber: billingEntity.vatNumber,
                  registeredCompanyNumber: billingEntity.registeredCompanyNumber,
                  billingAddress: addressToPayloadWithDefault(
                      billingEntity.billingAddress,
                      AddressType.Entity,
                  ),
                  organizationId: billingEntity.organization?.id || null,
                  isDefault: billingEntity.isDefault,
                  countries: billingEntity.countries,
                  twoApiEnabled: billingEntity.twoApiEnabled,
                  twoSpreadsheetEnabled: billingEntity.twoSpreadsheetEnabled,
                  isContractSigned: billingEntity.isContractSigned,
                  twoRecourseLimit: billingEntity.twoRecourseLimit,
                  contacts: billingEntity.contacts,
                  deelSync: null,
              }
            : {
                  ...emptyBillingEntityPayload,
                  organizationId,
                  billingAddress: emptyAddress,
              },
        initialDeps: [billingEntity],
        onSubmit: mutation.mutate,
        validate: validator<BillingEntityPayload>({
            name: isRequired('Name is required'),
            currency: isRequired('Currency is required'),
            paymentTerms: isRequired('Payment terms are required'),
            countries: contactsRequired('Countries required'),
            billingAddress: validateAddress,
            registeredCompanyNumber: [
                isRequired('Registered company number is required'),
                requireRegistrationNumberFormat('US format (12-1234567), UK format (12345678)'),
            ],
            contacts: (contracts, payload) =>
                validateArrayField<BillingEntityPayload, 'contacts'>({
                    // This is optional, this rule will apply to the array itself
                    selfRules: (_, p) => {
                        const defaultContact = p.contacts.filter(v => v.isDefault).length;
                        if (defaultContact > 1) {
                            return 'One default contact allowed';
                        }
                        if (p.twoApiEnabled) {
                            return !defaultContact ? 'Two integration requires default contact' : undefined;
                        }
                        return undefined;
                    },
                    // Here you can define the rules for each item in the array
                    fieldsValidator: validator<ContactPayload>({
                        phone: [
                            requireContactIfTwo(payload.twoApiEnabled, 'Phone number is required'),
                            isValidPhone('Phone number must be valid'),
                        ],
                        firstName: [requireContactIfTwo(payload.twoApiEnabled, 'First name is required')],
                        lastName: [requireContactIfTwo(payload.twoApiEnabled, 'Last name is required')],
                        email: [
                            requireContactIfTwo(payload.twoApiEnabled, 'Email is required'),
                            isValidEmailAddress('Email must be valid'),
                        ],
                    }),
                })(contracts, payload),
        }),
    });

    const contacts = useFormArrayField(form.fields.contacts, emptyContactPayload);
    return {
        isLoading: mutation.isPending,
        isError: mutation.isError,
        contacts,
        form,
    };
};

export type UseBillingEntityForm = ReturnType<typeof useBillingEntityForm>;
