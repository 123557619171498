import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, assignmentService, ReplaceAssignmentPayload } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useReplaceAssignment = (assignmentId: number) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (v: ReplaceAssignmentPayload) => assignmentService.replace(assignmentId, v),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Replacement created',
            });
        },
    });

    const replace = (deliveryAddressId: UUID) => {
        mutation.mutate({ deliveryAddressId });
    };

    return {
        replace,
    };
};
