import {
    AuditableModelDeprecatedDto,
    HofyWarehouseType,
    isHofyWarehouse,
    isOrganizationWarehouse,
    OrganizationWarehouseType,
    Ownership,
    UserRefDto,
    WarehouseDto,
} from '@hofy/api-shared';
import { Country } from '@hofy/global';

interface HofyWarehouseDto {
    type: HofyWarehouseType;
    hofySubsidiaryId: number;
    countries: Country[];
    isWarehouseBinRequired: boolean;
    dataErasureSupported: boolean;
}

interface OrganizationWarehouseDto {
    type: OrganizationWarehouseType;
    organizationId: number;
    primaryManager: UserRefDto | null;
    secondaryManager: UserRefDto | null;
}

interface WarehouseDetailsDto extends AuditableModelDeprecatedDto, WarehouseDto {
    hofyWarehouse: HofyWarehouseDto | null;
    organizationWarehouse: OrganizationWarehouseDto | null;
}

export type HofyWarehouseDetailsDto = WarehouseDetailsDto & {
    ownership: Ownership.Hofy;
    hofyWarehouse: HofyWarehouseDto;
    organizationWarehouse: null;
};

export type OrganizationWarehouseDetailsDto = WarehouseDetailsDto & {
    ownership: Ownership.Organization;
    hofyWarehouse: null;
    organizationWarehouse: OrganizationWarehouseDto;
};

export type WarehouseDetailsUnionDto = HofyWarehouseDetailsDto | OrganizationWarehouseDetailsDto;

export const isAdminHofyWarehouse = (
    warehouse?: WarehouseDetailsUnionDto | null,
): warehouse is HofyWarehouseDetailsDto => {
    return isHofyWarehouse(warehouse);
};

export const isAdminOrganizationWarehouse = (
    warehouse?: WarehouseDetailsUnionDto | null,
): warehouse is OrganizationWarehouseDetailsDto => {
    return isOrganizationWarehouse(warehouse);
};

export const isMainHofyWarehouse = (
    warehouse?: WarehouseDetailsUnionDto | null,
): warehouse is HofyWarehouseDetailsDto => {
    return isAdminHofyWarehouse(warehouse) && warehouse.hofyWarehouse.type === HofyWarehouseType.Main;
};

export const is3rdPartyHofyWarehouse = (
    warehouse?: WarehouseDetailsUnionDto | null,
): warehouse is HofyWarehouseDetailsDto => {
    return (
        isAdminHofyWarehouse(warehouse) &&
        (warehouse.hofyWarehouse.type === HofyWarehouseType.Virtual ||
            warehouse.hofyWarehouse.type === HofyWarehouseType.Partner)
    );
};
