import { keyBy } from 'lodash';
import React, { forwardRef, ReactElement, Ref, useMemo, useState } from 'react';

import { AdminOrganizationsFilters } from '@hofy/api-admin';
import {
    LabeledSelectSearch,
    LabeledSelectSearchNullableStringProps,
    LabeledSelectSearchOnlyStringProps,
} from '@hofy/ui';

import { useOrganizationsRefsQuery } from '../../../store/organizations/useOrganizationsQuery';

interface BaseProps {
    filters?: AdminOrganizationsFilters;
}

type LabeledOrganizationNormalProps = Omit<
    LabeledSelectSearchOnlyStringProps<number>,
    'isError' | 'toText' | 'options'
> &
    BaseProps;
type LabeledOrganizationNullableProps = Omit<
    LabeledSelectSearchNullableStringProps<number>,
    'isError' | 'toText' | 'options'
> &
    BaseProps;

export type LabeledOrganizationProps = LabeledOrganizationNormalProps | LabeledOrganizationNullableProps;

export const LabeledOrganizationSelect = forwardRef(
    ({ ...rest }: LabeledOrganizationProps, ref: Ref<HTMLDivElement>) => {
        const { value } = rest;

        const [search, setSearch] = useState('');

        const { data } = useOrganizationsRefsQuery(value ? [value] : [], search);
        const nameMap = useMemo(() => keyBy(data, v => v.id), [data]);

        return (
            <LabeledSelectSearch<number>
                toText={org => nameMap[org]?.name}
                options={data.map(v => v.id)}
                onSearchChange={setSearch}
                ref={ref}
                {...rest}
            />
        );
    },
) as (props: LabeledOrganizationProps & { ref?: Ref<HTMLDivElement> }) => ReactElement;
