import React, { FC } from 'react';

import { InvoiceEntryDetailsDto } from '@hofy/api-admin';
import {
    ContractType,
    InvoiceEntryType,
    periodRequiredTypes,
    productCategoryRequiredTypes,
    useTrContractType,
} from '@hofy/api-shared';
import { FormRow } from '@hofy/common';
import { FormCategorySelect } from '@hofy/product';
import {
    Box,
    FormContainer,
    FormDateInput,
    FormDecimalInput,
    FormNumberInput,
    FormPriceInput,
    FormSection,
    FormSelect,
    FormTextarea,
    Paragraph4,
} from '@hofy/ui';

import { AuditSection } from '../../../../components/domain/auditingInline/AuditSection';
import { FormHofySubsidiarySelect } from '../../../../components/domain/hofySubsidiaries/FormHofySubsidiarySelect';
import { UseInvoiceEntryForm } from '../../../../store/invoiceEntries/useInvoiceEntryForm';
import { useTrInvoiceEntryType } from '../../../../store/invoiceEntries/useTrInvoiceEntryType';
import { BillingEntityDropdown } from './BillingEntityDropdown';

interface InvoiceEntryFormProps {
    formState: UseInvoiceEntryForm;
    entryTypes: InvoiceEntryType[];
    organizationId?: number;
    editable?: boolean;
    invoiceEntry?: InvoiceEntryDetailsDto;
    availableContractTypes: ContractType[];
}

export const InvoiceEntryForm: FC<InvoiceEntryFormProps> = ({
    formState: {
        form,
        setDiscount,
        setUnitPrice,
        setPrice,
        changeBillingEntity,
        changeType,
        changeContractType,
    },
    entryTypes,
    editable = true,
    organizationId,
    invoiceEntry,
    availableContractTypes,
}) => {
    const trEntryType = useTrInvoiceEntryType();
    const trContractType = useTrContractType();
    const canChangeSubsidiary = editable && !form.values.contractId;

    const isCreateFlow = invoiceEntry === undefined;
    const hasTypeSelected = form.values.type !== null;
    const typeRequiresPeriod = hasTypeSelected && periodRequiredTypes.includes(form.values.type!);
    const typeRequiresProductCategory =
        hasTypeSelected && productCategoryRequiredTypes.includes(form.values.type!);

    return (
        <Box marginTop={10}>
            <FormContainer>
                {availableContractTypes.length > 1 && (
                    <FormSelect
                        marginRight={20}
                        api={form.fields.contractType}
                        options={availableContractTypes}
                        toText={trContractType}
                        onChange={changeContractType}
                        label='Selected contract'
                    />
                )}
                <FormSelect
                    api={form.fields.type}
                    label='Type'
                    marginRight={20}
                    options={entryTypes}
                    onChange={changeType}
                    toText={trEntryType}
                    disabled={!editable || !!invoiceEntry}
                />
                {isCreateFlow && typeRequiresProductCategory && (
                    <FormCategorySelect
                        label='Product category'
                        api={form.fields.productCategory}
                        nullable
                        isRequired
                    />
                )}
                <Box>
                    <FormTextarea
                        label='Description'
                        api={form.fields.description}
                        rows={5}
                        disabled={!editable}
                    />
                    {form.values.contractId && (
                        <Paragraph4 marginTop={4}>Contract id suffix will be automatically added</Paragraph4>
                    )}
                </Box>
                <FormSection label='Invoice'>
                    <FormDateInput label='Date' api={form.fields.invoiceTime} disabled={!editable} />
                    {typeRequiresPeriod && (
                        <>
                            <FormDateInput
                                label='From'
                                api={form.fields.invoicePeriodFrom}
                                disabled={!editable}
                                nullable
                            />
                            <FormDateInput
                                label='To'
                                api={form.fields.invoicePeriodTo}
                                disabled={!editable}
                                nullable
                            />
                        </>
                    )}
                </FormSection>
                <FormSection label='Price'>
                    <FormRow>
                        <FormPriceInput
                            label='Unit Price'
                            api={form.fields.unitPrice}
                            onChange={setUnitPrice}
                            disabled={!editable}
                        />
                        <FormDecimalInput
                            label='Discount'
                            unit='%'
                            api={form.fields.discount}
                            onChange={setDiscount}
                            precision={2}
                            disabled={!editable}
                        />
                        <FormPriceInput
                            label='Price'
                            api={form.fields.price}
                            onChange={setPrice}
                            disabled={!editable}
                        />
                    </FormRow>
                    <FormRow>
                        <FormNumberInput label='Quantity' api={form.fields.quantity} disabled={!editable} />
                    </FormRow>
                </FormSection>
                <FormSection label='Billing'>
                    <FormRow>
                        <FormHofySubsidiarySelect
                            label='Hofy subsidiary'
                            api={form.fields.hofySubsidiaryId}
                            disabled={!canChangeSubsidiary}
                            nullable
                        />
                        <BillingEntityDropdown
                            organizationId={organizationId}
                            onChange={(_, s) => changeBillingEntity(s)}
                            label='Billing entity'
                            value={form.values.billingEntityId}
                            isError={form.errors.billingEntityId}
                            disabled={!canChangeSubsidiary}
                        />
                    </FormRow>
                </FormSection>
                {invoiceEntry && <AuditSection model={invoiceEntry} />}
            </FormContainer>
        </Box>
    );
};
