import { omit } from 'lodash';

import { AdminInvoiceEntriesFilters } from '@hofy/api-admin';
import { InvoiceEntryType, TaxStatus } from '@hofy/api-shared';
import { DateString } from '@hofy/global';
import { countObjectValues } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import {
    useArrayQueryParam,
    useBooleanQueryParam,
    useDateRangeQueryParam,
    useNumberQueryParam,
    useStringQueryParam,
} from '@hofy/router';

export const useInvoiceEntriesFilters = () => {
    const [organization, setOrganizations] = useNumberQueryParam('organizations');
    const [billingEntity, setBillingEntity] = useArrayQueryParam<number>('billingEntity');
    const [invoiceDate, setInvoiceDate] = useStringQueryParam<DateString>('invoiceDate');
    const [dateRange, setDateRange] = useDateRangeQueryParam('dateRange');
    const [invoiceEntryType, setInvoiceEntryType] = useStringQueryParam<InvoiceEntryType>('contractStatus');
    const [taxStatus, setTaxStatus] = useStringQueryParam<TaxStatus>('taxStatus');
    const [search, setSearch] = useStringQueryParam('search', '');
    const [isInvoiced, setIsInvoiced] = useBooleanQueryParam('isInvoiced');
    const [includeDeleted, setIncludeDeleted] = useBooleanQueryParam('includeDeleted');

    const filters = useStructMemo<AdminInvoiceEntriesFilters>({
        organization,
        billingEntity,
        invoiceDate,
        invoiceEntryType,
        isInvoiced,
        search,
        taxStatus,
        dateRange,
        repaymentPlan: null,
        invoice: null,
        includeDeleted,
        salesOrder: null,
    });

    const filtersToCount = omit(filters, 'search', 'organization', 'toDate', 'includeDeleted');
    const filterCount = countObjectValues(filtersToCount);

    return {
        filters,
        filterCount,
        setOrganizations,
        setBillingEntity,
        setInvoiceDate,
        setInvoiceEntryType,
        setSearch,
        setIsInvoiced,
        setTaxStatus,
        setDateRange,
        setIncludeDeleted,
    };
};
