import { xor } from 'lodash';
import React, { FC } from 'react';

import {
    JobContext,
    useAdminsQuery,
    useHofyWarehousesQuery,
    useJobsSummary,
    usePurchaseOrders,
    useSuppliersQuery,
} from '@hofy/api-admin';
import {
    allNetsuiteSyncStatuses,
    allPurchaseOrderStatuses,
    netsuiteStatusColors,
    NetsuiteSyncStatus,
    Permission,
    PurchaseOrderStatus,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { ExportButton, Page } from '@hofy/common';
import { Color } from '@hofy/theme';
import {
    Button,
    FilterChipList,
    LabeledSwitch,
    PageHeader,
    Paragraph3,
    SearchInput,
    SvgIcon,
} from '@hofy/ui';

import { BlockFilterButton } from '../../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../../components/design/blockFilters/BlockFilterContainer';
import { EnumMultiBlockFilter } from '../../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../../components/design/blockFilters/hooks/useBlockFilters';
import { WarehouseFilterRenderer } from '../../../components/domain/filters/WarehouseFilterRenderer';
import { JobsSummaryIndicator } from '../../../components/domain/job/JobsSummaryIndicator';
import { useTrNetsuiteSyncStatus } from '../../../store/netsuite/useTrNetsuiteSyncStatus';
import { InventoryTab } from '../../../store/purchaseOrders/types/InventoryTab';
import { useAdminPurchaseOrderFilters } from '../../../store/purchaseOrders/useAdminPurchaseOrderFilters';
import { usePurchaseOrdersExport } from '../../../store/purchaseOrders/usePurchaseOrdersExport';
import { useTrPurchaseOrderStatus } from '../../../store/purchaseOrders/useTrPurchaseOrderStatus';
import { AdminFilterRenderer } from '../../assignmentsPage/components/users/AdminFilterRenderer';
import { InventoryTabs } from '../InventoryTabs';
import { AdminBlockFilter } from './components/filter/AdminBlockFilter';
import { SkuBlockFilter } from './components/filter/SkuBlockFilter';
import { SkuFilterRenderer } from './components/filter/SkuFilterRenderer';
import { SupplierBlockFilter } from './components/filter/SupplierBlockFilter';
import { SupplierFilterRenderer } from './components/filter/SupplierFilterRenderer';
import { WarehouseBlockFilter } from './components/filter/WarehouseBlockFilter';
import { PurchaseOrderTable } from './components/table/PurchaseOrderTable';
import { useVariants } from './hooks/useVariants';
import { PurchaseOrdersPageMenu } from './PurchaseOrdersPageMenu';

interface PurchaseOrdersPageProps {
    onOpenPurchaseOrder(id: number): void;
    onNewPurchaseOrder(): void;
    onOpenJobs(): void;
}

export const PurchaseOrdersPage: FC<PurchaseOrdersPageProps> = ({
    onOpenPurchaseOrder,
    onNewPurchaseOrder,
    onOpenJobs,
}) => {
    const {
        filters,
        filterCount,
        setSearch,
        setPurchaseOrderStatuses,
        setCreatedByUsers,
        setSuppliers,
        setWarehouses,
        setVariants,
        toggleIsDropship,
        setNetsuiteStatuses,
    } = useAdminPurchaseOrderFilters([]);
    const { purchaseOrders, purchaseOrdersIsLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
        usePurchaseOrders(filters);

    const { hasPermission } = useSession();

    const { data: admins } = useAdminsQuery();
    const { data: suppliers } = useSuppliersQuery();
    const { data: warehouses } = useHofyWarehousesQuery();
    const trPurchaseOrderStatus = useTrPurchaseOrderStatus();
    const { variants } = useVariants();
    const exportPurchaseOrders = usePurchaseOrdersExport(filters);
    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();
    const trNetsuiteStatus = useTrNetsuiteSyncStatus();
    const { data: jobsSummary } = useJobsSummary([JobContext.PurchaseOrders]);

    return (
        <Page pageId='purchase-orders'>
            <PageHeader
                title='Purchase orders'
                titleSlot={<JobsSummaryIndicator onClick={onOpenJobs} jobsSummary={jobsSummary} />}
                rightSlot={
                    <>
                        <ExportButton onClick={exportPurchaseOrders} />
                        <LabeledSwitch
                            label={<Paragraph3 color={Color.ContentPrimary}>Is dropship</Paragraph3>}
                            size='small'
                            checked={filters.isDropship}
                            onChange={value => toggleIsDropship(value)}
                        />
                        <SearchInput value={filters.search} onChange={setSearch} autoFocus />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                        <Button
                            leftIcon={SvgIcon.Add}
                            label='New purchase order'
                            disabled={!hasPermission(Permission.AdminPurchaseOrdersCreate)}
                            onClick={onNewPurchaseOrder}
                        />
                        <PurchaseOrdersPageMenu />
                    </>
                }
                tabsSlot={<InventoryTabs active={InventoryTab.PurchaseOrders} />}
            />

            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <AdminBlockFilter
                    admins={admins}
                    selected={filters.createdByUsers}
                    setSelected={setCreatedByUsers}
                />
                <EnumMultiBlockFilter<PurchaseOrderStatus>
                    title='Status'
                    selected={filters.purchaseOrderStatuses || []}
                    onChange={setPurchaseOrderStatuses}
                    items={allPurchaseOrderStatuses}
                    renderItem={trPurchaseOrderStatus}
                />
                <SupplierBlockFilter
                    suppliers={suppliers}
                    selected={filters.suppliers}
                    setSelected={setSuppliers}
                />
                <WarehouseBlockFilter
                    warehouses={warehouses}
                    selected={filters.warehouses}
                    setSelected={setWarehouses}
                />
                <SkuBlockFilter variants={variants} selected={filters.variants} setSelected={setVariants} />
                <EnumMultiBlockFilter<NetsuiteSyncStatus>
                    title='Netsuite sync'
                    icon={SvgIcon.Link}
                    selected={filters.netsuiteStatuses}
                    onChange={setNetsuiteStatuses}
                    items={allNetsuiteSyncStatuses}
                    renderItem={status => trNetsuiteStatus(status)}
                />
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                {filters.createdByUsers.length > 0 && (
                    <AdminFilterRenderer
                        admins={admins}
                        adminIds={filters.createdByUsers}
                        onChange={setCreatedByUsers}
                    />
                )}
                <FilterChipList
                    selected={filters.purchaseOrderStatuses}
                    color='purple'
                    toLabel={trPurchaseOrderStatus}
                    toKey={status => status}
                    onClear={status => setPurchaseOrderStatuses(xor(filters.purchaseOrderStatuses, [status]))}
                />
                <SupplierFilterRenderer
                    suppliers={suppliers}
                    supplierIds={filters.suppliers}
                    onChange={setSuppliers}
                />
                <WarehouseFilterRenderer
                    warehouses={warehouses}
                    warehouseIds={filters.warehouses}
                    onChange={setWarehouses}
                />
                <SkuFilterRenderer variants={variants} variantIds={filters.variants} onChange={setVariants} />
                <FilterChipList
                    toKey={status => status}
                    selected={filters.netsuiteStatuses}
                    toLabel={trNetsuiteStatus}
                    onClear={value => setNetsuiteStatuses(xor(filters.netsuiteStatuses, [value]))}
                    color={p => netsuiteStatusColors[p]}
                />
            </BlockFilterChipContainer>
            <PurchaseOrderTable
                purchaseOrders={purchaseOrders}
                onOpenPurchaseOrder={onOpenPurchaseOrder}
                infinityScroll={{
                    hasMore: hasNextPage,
                    isLoading: purchaseOrdersIsLoading,
                    isLoadingMore: isFetchingNextPage,
                    loadMore: fetchNextPage,
                }}
            />
        </Page>
    );
};
