import React, { FC } from 'react';

import { TermAndConditionDto } from '@hofy/api-admin';
import { nowISODate } from '@hofy/helpers';
import {
    FormContainer,
    FormDateInput,
    FormModal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    SubmitButton,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useAddOrganizationTermAndCondition } from '../../../store/organizations/useAddOrganizationTermAndCondition';
import { TermAndConditionFormSelect } from './TermAndConditionFormSelect';

interface AddTermAndConditionModalProps {
    onClose(): void;
    organizationId: number;
    termsAndConditions: TermAndConditionDto[];
}

export const AddTermAndConditionModal: FC<AddTermAndConditionModalProps> = ({
    organizationId,
    termsAndConditions,
    onClose,
}) => {
    const defaultTermAndCondition = termsAndConditions.find(t => t.isDefault) || termsAndConditions[0];
    const { form, isLoading, isError } = useAddOrganizationTermAndCondition(
        organizationId,
        defaultTermAndCondition,
        onClose,
    );

    return (
        <FormModal
            width={550}
            onSubmit={form.submit}
            onClose={form.discard}
            isLoading={isLoading}
            isError={isError}
        >
            <ModalHeader title='Add term and condition' borderBottom />
            <ModalContent>
                <FormContainer>
                    <TermAndConditionFormSelect
                        termsAndConditions={termsAndConditions}
                        api={form.fields.termAndCondition}
                    />
                    <FormDateInput
                        label='Starts at'
                        api={form.fields.startOn}
                        minDate={nowISODate()}
                        isRequired
                        nullable
                    />
                </FormContainer>
            </ModalContent>
            <ModalFooter borderTop>
                <CancelButton onClick={form.discard} />
                <SubmitButton label='Add' />
            </ModalFooter>
        </FormModal>
    );
};
