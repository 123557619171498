import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AddLoanerPayload, assignmentsCacheKey, assignmentService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useAddLoaner = (assignmentId: number) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (p: AddLoanerPayload) => assignmentService.addLoaner(assignmentId, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Loaner created',
            });
        },
    });

    const addLoaner = (deliveryAddressId: UUID) => {
        mutation.mutate({ deliveryAddressId });
    };

    return {
        addLoaner,
    };
};
