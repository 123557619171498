import React, { FC } from 'react';

import { Overlay } from '@hofy/common';
import { Color } from '@hofy/theme';
import { Form, PageHeader, SubmitButton } from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { Footer } from '../../../../components/design/layout/Footer';
import { useCreateUpdateBillingEntity } from '../../../../store/invoicing/billingEntities/useCreateUpdateBillingEntity';
import { BillingEntityForm } from './BillingEntityForm';

interface CreateUpdateBillingEntityOverlayProps {
    onClose(): void;
    organizationId: number | null;
    billingEntityId?: number;
}

export const CreateUpdateBillingEntityOverlay: FC<CreateUpdateBillingEntityOverlayProps> = ({
    onClose,
    billingEntityId,
    organizationId,
}) => {
    const billingEntityForm = useCreateUpdateBillingEntity(onClose, organizationId, billingEntityId);
    const title = billingEntityId ? 'Edit billing entity' : 'Create billing entity';
    return (
        <Overlay column flex='auto' bg={Color.BackgroundDefault}>
            <PageHeader title={title} />
            <Form
                onSubmit={billingEntityForm.form.submit}
                isLoading={billingEntityForm.isLoading}
                isError={billingEntityForm.isError}
                flex='auto'
                column
            >
                <BillingEntityForm billingEntityForm={billingEntityForm} billingEntityId={billingEntityId} />
                <Footer>
                    <CancelButton onClick={onClose} label='Cancel' />
                    <SubmitButton label='Save' />
                </Footer>
            </Form>
        </Overlay>
    );
};
