import React, { FC } from 'react';

import {
    ContractDetailsDtoWithRental,
    getRentalContractStatusDate,
    RentalSubContractDto,
} from '@hofy/api-admin';
import {
    allRentalContractStatuses,
    isEndingRental,
    RentalContractStatus,
    useTrAnyContractType,
    useTrContractEndOption,
    useTrContractExtendOption,
    useTrPaymentSchema,
    useTrPaymentType,
    useTrRentalContractStatus,
    useTrRentalTerm,
} from '@hofy/api-shared';
import { StatusHistory } from '@hofy/common';
import { useDecimal, usePrice } from '@hofy/hooks';
import { Badge, BooleanBadge, Box, FormGridRow, FormSection, Labeled, LabeledText } from '@hofy/ui';
import { ContractStatusChip } from '@hofy/ui-domain';

import { AuditSection } from '../../../components/domain/auditingInline/AuditSection';
import { StoreAndReuseLocationChip } from '../../../components/domain/contracts/StoreAndReuseOptionChip';
import { StoreAndReuseStatusChip } from '../../../components/domain/contracts/StoreAndReuseStatusChip';
import { useAdminI18n } from '../../../i18n/useAdminI18n';
import { OrganizationLink } from '../../organizationsPage/OrganizationDetailsLink';
import { BillingSection } from './components/BillingSection';
import { MainItemSection } from './components/MainItemSection';
import { PriceBreakdownHintTooltip } from './components/PriceBreakdownHintTooltip';
import {
    ContractBillingAddressLabeledText,
    ContractBillingCountryLabel,
    ContractBudgetLabeledText,
    ContractProductDetails,
    ContractTermsAndConditionsLabeledText,
} from './ContractDetailsTab';
import { ManagementSection } from './ManagementSection';

interface ContractDetailsTabProps {
    contract: ContractDetailsDtoWithRental;
    mainSubContract: RentalSubContractDto;
}

export const RentalContractDetailsTab: FC<ContractDetailsTabProps> = ({ contract, mainSubContract }) => {
    const { formatPrice } = usePrice();
    const { formatDecimal, formatPercent } = useDecimal();
    const trStatus = useTrRentalContractStatus();
    const trSchema = useTrPaymentSchema();
    const trTerm = useTrRentalTerm();
    const { trPlural } = useAdminI18n();
    const trContractType = useTrAnyContractType();
    const trContractEndOption = useTrContractEndOption();
    const trContractExtendOption = useTrContractExtendOption();
    const trPaymentType = useTrPaymentType();

    const shouldDisplayEoc = mainSubContract.status === RentalContractStatus.Active;
    const isEnding = isEndingRental({
        rentalContract: mainSubContract,
    });
    const renderStatusChip = () => {
        return (
            <>
                <ContractStatusChip status={mainSubContract.status} />
                {isEnding && mainSubContract.executedContractEndOption && (
                    <Badge
                        marginTop={5}
                        color='orange'
                        label={trContractEndOption(mainSubContract.executedContractEndOption)}
                    />
                )}
            </>
        );
    };
    return (
        <Box overflow='auto' fullHeight row alignItems='flex-start' paddingHorizontal={40}>
            <Box flex={1} marginVertical={30} marginRight={20} gap={30} column>
                <FormSection label='Details'>
                    <FormGridRow columns={4}>
                        <LabeledText label='Contract id' content={contract.idDeprecated} />
                        <LabeledText label='Contract public id' content={contract.publicId} />
                        <LabeledText label='Contract uuid' content={contract.uuid} />
                        <LabeledText label='Main subcontract id' content={mainSubContract.idDeprecated} />
                    </FormGridRow>
                    <FormGridRow columns={4}>
                        <LabeledText
                            label='Organization'
                            content={
                                <OrganizationLink id={contract.organization.id}>
                                    {contract.organization.name}
                                </OrganizationLink>
                            }
                        />
                        <Labeled label='Status' content={renderStatusChip()} />
                        <ContractBillingCountryLabel billingToAddress={mainSubContract.billingToAddress} />
                        <ContractBillingAddressLabeledText
                            billingToAddress={mainSubContract.billingToAddress}
                        />
                    </FormGridRow>
                    <ContractProductDetails product={contract.product} variant={contract.variant} />
                    <FormGridRow columns={4}>
                        <LabeledText label='Contract type' content={trContractType(contract.type)} />
                        {!!contract.rentalTerm && (
                            <LabeledText label='Rental term' content={trTerm(contract.rentalTerm)} />
                        )}
                        {!!contract.rentalContract.rentalLength && (
                            <LabeledText
                                label='Contract length'
                                content={trPlural('month', contract.rentalContract.rentalLength)}
                            />
                        )}
                        <LabeledText
                            label='Pricing schema'
                            content={trSchema(mainSubContract.paymentSchema)}
                        />
                        <LabeledText label='Payment type' content={trPaymentType(contract.paymentType)} />
                    </FormGridRow>
                    <FormGridRow columns={4}>
                        <Labeled
                            label='Redistributable'
                            content={<BooleanBadge value={mainSubContract.possibleRedistribution || false} />}
                        />
                        <ContractTermsAndConditionsLabeledText termAndCondition={contract.termAndCondition} />
                        <ContractBudgetLabeledText budgetConsumption={contract.budgetConsumption} />
                    </FormGridRow>
                    <FormGridRow columns={4}>
                        {shouldDisplayEoc && contract.rentalContract.contractExtendOption && (
                            <LabeledText
                                label='Contract extend option'
                                content={trContractExtendOption(contract.rentalContract.contractExtendOption)}
                            />
                        )}
                        {shouldDisplayEoc && contract.rentalContract.contractEndOption && (
                            <LabeledText
                                label='Contract end option'
                                content={trContractEndOption(contract.rentalContract.contractEndOption)}
                            />
                        )}
                    </FormGridRow>
                    <FormSection label='Store and reuse'>
                        <FormGridRow columns={4}>
                            <Labeled
                                label='Status'
                                content={
                                    <StoreAndReuseStatusChip
                                        status={mainSubContract.storeAndReuse?.status ?? null}
                                    />
                                }
                            />

                            <LabeledText
                                label='Store and reuse fee'
                                content={formatPrice(mainSubContract.storeAndReuseFee)}
                            />

                            <Labeled
                                label='All items collected'
                                content={
                                    <BooleanBadge
                                        value={mainSubContract.isRedistributableOrReusable || false}
                                        offColor='grey'
                                    />
                                }
                            />
                            <Labeled
                                label='Reuse option'
                                content={
                                    <StoreAndReuseLocationChip
                                        location={mainSubContract.storeAndReuse?.collectToLocation ?? null}
                                    />
                                }
                            />
                        </FormGridRow>
                    </FormSection>
                    {contract.managementContract && (
                        <ManagementSection
                            label='Management'
                            managementContract={contract.managementContract}
                        />
                    )}
                </FormSection>
                <FormSection label='Status history'>
                    <StatusHistory
                        allStatuses={allRentalContractStatuses}
                        dateGetter={s => getRentalContractStatusDate(contract, s)}
                        labelFormatter={trStatus}
                    />
                </FormSection>
                <AuditSection model={contract} />
            </Box>
            <Box flex={1} marginVertical={30} marginLeft={20} column gap={30}>
                <FormSection label={<PriceBreakdownHintTooltip label='Pricing / main subcontract' />}>
                    <FormGridRow columns={2}>
                        <LabeledText label='Base price' content={formatPrice(mainSubContract.basePrice)} />
                        <LabeledText label='Unit price' content={formatPrice(mainSubContract.unitPrice)} />
                    </FormGridRow>
                    <FormGridRow columns={2}>
                        <LabeledText label='Price' content={formatPrice(mainSubContract.price)} />
                        <LabeledText
                            label='Resell price'
                            content={formatPrice(mainSubContract.resellPrice)}
                        />
                    </FormGridRow>
                    <FormGridRow columns={2}>
                        <LabeledText
                            label='Country multiplier'
                            content={formatDecimal(mainSubContract.countryMultiplier)}
                        />
                        <LabeledText
                            label='Discount'
                            content={formatPercent(mainSubContract.discount || '0')}
                        />
                    </FormGridRow>
                    <FormGridRow columns={2}>
                        <LabeledText label='Discount reason' content={mainSubContract.discountReason} />
                        {contract.rentalContract.rolloverPrice !== null && (
                            <LabeledText
                                label='Extended Price (rollover)'
                                content={formatPrice(contract.rentalContract.rolloverPrice)}
                            />
                        )}
                    </FormGridRow>
                    <FormGridRow columns={2}>
                        <LabeledText
                            label='End of contract purchase price'
                            content={formatPrice(mainSubContract.purchaseFee)}
                        />
                        <LabeledText
                            label='End of contract cancellation fee'
                            content={formatPrice(mainSubContract.cancellationFee)}
                        />
                    </FormGridRow>
                    <FormGridRow columns={2}>
                        <LabeledText
                            label='Store fee'
                            content={formatPercent(mainSubContract.storefrontFee || '0')}
                        />
                        <LabeledText
                            label='Cancellation discount'
                            content={formatPercent(mainSubContract.cancellationDiscount || '0')}
                        />
                    </FormGridRow>
                </FormSection>
                <BillingSection
                    hofySubsidiary={mainSubContract.hofySubsidiary}
                    billingEntity={mainSubContract.billingEntity}
                    label='Invoicing / main subcontract'
                />
                <MainItemSection item={contract.mainItem} />
            </Box>
        </Box>
    );
};
