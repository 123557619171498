import React, { FC, useCallback, useEffect } from 'react';

import { UserAssignmentDto } from '@hofy/api-admin';
import {
    belongsToOrganization,
    getFirstActiveRentalContractCountry,
    Role,
    ShipmentType,
    shipmentTypeAssignmentStatus,
    UserWithCountryRefDto,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { redistributableTo } from '@hofy/global';
import { Alert, AsyncButton, Button, FormContainer, LabeledSwitch, Paragraph3, SvgIcon } from '@hofy/ui';

import { LabeledUserSelect } from '../../../components/domain/user/LabeledUserSelect';
import { useInPersonTransfer } from '../../../store/assignments/useInPersonTransfer';
import { AssignmentSelection } from '../createShipmentSlideout/AssignmentSelection';

interface InPersonTransferSlideoutProps {
    userId: number;
    organizationId: number;
    assignments: UserAssignmentDto[];
    onSuccess(): void;
    onClose(): void;
}

export const InPersonTransferSlideout: FC<InPersonTransferSlideoutProps> = ({
    userId,
    organizationId,
    assignments,
    onSuccess,
    onClose,
}) => {
    const { hasRole } = useSession();

    const { form, isLoading, assignmentSelection } = useInPersonTransfer(assignments, onSuccess);

    const redistributableInCountry = getFirstActiveRentalContractCountry(assignmentSelection.assignments);

    const allBelongToOrganization = assignmentSelection.assignments.every(a =>
        belongsToOrganization(a.item?.ownership.type ?? null),
    );

    useEffect(() => {
        if (redistributableInCountry) {
            form.setValues({
                toUserId: undefined,
            });
        } else {
            form.setValues({
                allowTransfersBetweenTaxZones: false,
            });
        }
    }, [redistributableInCountry]);

    const userPredicate = useCallback(
        (user: UserWithCountryRefDto) => {
            return (
                user.isOffBoarded === false &&
                (form.values.allowTransfersBetweenTaxZones ||
                    allBelongToOrganization ||
                    redistributableTo(redistributableInCountry, user.country))
            );
        },
        [redistributableInCountry, form.values.allowTransfersBetweenTaxZones],
    );

    return (
        <Slideout width={600} gap={24} onClose={onClose}>
            <SlideoutHeader title='In-person transfer' />
            <SlideoutContent column gap={24}>
                <Alert
                    description='This is an in-person transfer and it does not create a shipment'
                    type='neutral'
                />
                <FormContainer spacing={20}>
                    <LabeledUserSelect
                        label='To user'
                        organizationId={organizationId}
                        userPredicate={userPredicate}
                        value={form.values.toUserId}
                        errorMessage={form.errors.toUserId?.toString()}
                        onChange={toUserId => {
                            form.setValues({
                                toUserId,
                            });
                        }}
                        isRequired
                        nullable
                    />
                    {hasRole(Role.SuperAdmin) && !allBelongToOrganization && redistributableInCountry && (
                        <LabeledSwitch
                            label='Allow transfers between different tax zones'
                            checked={form.values.allowTransfersBetweenTaxZones}
                            onChange={allowTransfersBetweenTaxZones => {
                                form.setValues({
                                    allowTransfersBetweenTaxZones,
                                });
                                if (!allowTransfersBetweenTaxZones && redistributableInCountry) {
                                    form.setValues({
                                        toUserId: undefined,
                                    });
                                }
                            }}
                        />
                    )}
                    {!form.values.allowTransfersBetweenTaxZones && (
                        <Paragraph3>
                            If any of the selected assignments is for an active rental item, you will not be
                            able to transfer out of customs region.
                        </Paragraph3>
                    )}
                    <AssignmentSelection
                        status={shipmentTypeAssignmentStatus[ShipmentType.Transfer]}
                        selection={assignmentSelection}
                        userId={userId}
                    />
                </FormContainer>
            </SlideoutContent>
            <SlideoutFooter>
                <Button
                    type='ghost'
                    negativeMargin
                    onClick={onClose}
                    label='Close'
                    leftIcon={SvgIcon.Cross}
                />
                <AsyncButton isLoading={isLoading} label='Transfer' onClick={form.submit} disableCheck />
            </SlideoutFooter>
        </Slideout>
    );
};
