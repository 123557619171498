import { useQuery } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import { useMemo } from 'react';

import { productCacheKey, productsService } from '@hofy/api-admin';
import { ProductCategory } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

export const useProductsByCategory = (
    category: ProductCategory | undefined,
    activeOnly: boolean,
    organizationId?: number,
    excludedProduct?: UUID,
) => {
    const { data: productsData, isLoading } = useQuery({
        queryKey: [productCacheKey],
        queryFn: productsService.getProducts,
        enabled: !!category,
    });

    const products = useMemo(() => {
        const mapProductVariants = (productsData || []).map(p => {
            const variants = p.variants.filter(
                v => !v.organization?.id || v.organization?.id === organizationId,
            );
            return {
                ...p,
                variants,
            };
        });
        const filteredProducts = mapProductVariants.filter(
            p =>
                p.category === category &&
                (activeOnly ? p.isActive : true) &&
                p.id !== excludedProduct &&
                p.variants.length > 0,
        );

        return sortBy(filteredProducts, p => p.name);
    }, [productsData, category, organizationId, excludedProduct]);

    return {
        isLoading,
        products,
    };
};
