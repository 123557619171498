import React, { FC } from 'react';

import { ContractAddonDto } from '@hofy/api-admin';
import { addonTypeColors, useTrAddonItem, useTrAddonType, useTrPaymentSchema } from '@hofy/api-shared';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { Badge, BaseTable, Box } from '@hofy/ui';

interface AddonsTableProps {
    addons: ContractAddonDto[];
}

export const AddonsTable: FC<AddonsTableProps> = ({ addons }) => {
    const trAddonType = useTrAddonType();
    const trAddonItemType = useTrAddonItem();
    const trPaymentSchema = useTrPaymentSchema();
    const { formatPrice } = usePrice();

    return (
        <BaseTable
            data={addons}
            toKey={addon => addon.id}
            columns={[
                {
                    id: 'id',
                    header: '#Id',
                    flexGrow: 0,
                    width: 100,
                    renderer: addon => `#${addon.id}`,
                },

                {
                    id: 'name',
                    header: 'Name',
                    flexGrow: 0,
                    width: 200,
                    renderer: addon => addon.addon.name,
                },

                {
                    id: 'type',
                    header: 'Addon type',
                    width: 160,
                    flexGrow: 0,
                    renderer: addon => (
                        <Badge
                            color={addonTypeColors[addon.addonType]}
                            label={trAddonType(addon.addonType)}
                        />
                    ),
                },
                {
                    id: 'price',
                    header: 'Monthly price',
                    flexGrow: 0,
                    width: 140,
                    renderer: addon => formatPrice(addon.price),
                },
                {
                    id: 'price',
                    header: 'Total price',
                    flexGrow: 0,
                    width: 100,
                    renderer: addon => formatPrice(addon.totalPrice),
                },
                {
                    id: 'resell_price',
                    header: 'Resell price',
                    flexGrow: 0,
                    width: 100,
                    renderer: addon => formatPrice(addon.resellPrice),
                },
                {
                    id: 'valid from',
                    header: 'Valid from',
                    flexGrow: 0,
                    width: 120,
                    renderer: addon => formatDate(addon.activeOn),
                },
                {
                    id: 'valid to',
                    header: 'Valid to',
                    flexGrow: 0,
                    width: 120,
                    renderer: addon => formatDate(addon.endOn),
                },
                {
                    id: 'payment_schema',
                    header: 'Payment schema',
                    flexGrow: 0,
                    width: 140,
                    renderer: addon => trPaymentSchema(addon.paymentSchema),
                },
                {
                    id: 'type',
                    header: 'Addon items',
                    flexGrow: 1,
                    renderer: addon => (
                        <Box row wrap gap={20}>
                            {addon.addonItems.map(item => (
                                <Badge key={item} color='grey' label={trAddonItemType(item)} />
                            ))}
                        </Box>
                    ),
                },
            ]}
        />
    );
};
