import React, { FC, Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { NoteDto } from '@hofy/api-shared';
import { DateTimeString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, BoxProps, Icon, Paragraph3, Span, SvgIcon } from '@hofy/ui';

import { ExpandableRow } from '../../utils/ExpandableRow';

interface NotesCellProps extends BoxProps {
    linkToNotes: string;
    label: string;
    notes: NoteDto[];
}

export const NotesCell: FC<NotesCellProps> = ({ notes, linkToNotes, label, ...rest }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <ExpandableRow
            isOpen={isExpanded}
            onClick={e => {
                e.stopPropagation();
                setIsExpanded(current => !current);
            }}
            column
            {...rest}
        >
            <Paragraph3 color={Color.ContentTertiary}>{label}</Paragraph3>
            <Box>
                <RenderNotes notes={notes} isExpanded={isExpanded} linkToNotes={linkToNotes} />
            </Box>
        </ExpandableRow>
    );
};

interface RenderNotesProps {
    notes: NoteDto[];
    isExpanded: boolean;
    linkToNotes: string;
}

const RenderNotes: FC<RenderNotesProps> = ({ notes, isExpanded, linkToNotes }) => {
    if (notes.length === 0) {
        return <NoteText>--</NoteText>;
    }

    return <NoteList isExpanded={isExpanded} linkToNotes={linkToNotes} notes={notes} />;
};

interface NoteListProps {
    isExpanded: boolean;
    linkToNotes: string;
    notes: NoteDto[];
}

const NoteList: FC<NoteListProps> = ({ isExpanded, linkToNotes, notes }) => {
    const renderNotes = isExpanded ? notes : [notes[0]];
    return (
        <>
            {renderNotes.map((note, index) => (
                <Fragment key={note.uuid}>
                    {index > 0 && <Divider />}
                    <NotesFont
                        textClomp={isExpanded ? undefined : 2}
                        overflow={isExpanded ? 'auto' : 'hidden'}
                        color={Color.ContentPrimary}
                    >
                        <Link to={linkToNotes} onClick={e => e.stopPropagation()}>
                            <Span color={Color.ContentPrimary} verticalAlign='middle' pointer>
                                <Icon size={12} svg={SvgIcon.Link} marginRight={4} />
                            </Span>
                        </Link>
                        {`${note.createdBy.name} (${formattedDate(note.createdAt)})`}
                        &nbsp;
                        <NoteText>{note.text}</NoteText>
                    </NotesFont>
                </Fragment>
            ))}
        </>
    );
};

const formattedDate = (date: DateTimeString | null) => {
    const currentYear = new Date().getFullYear().toString();
    if (formatDate(date, 'yyyy') === currentYear) {
        return formatDate(date, 'dd/MM');
    }
    return formatDate(date);
};

const NoteText = styled(Span)`
    color: ${Color.ContentSecondary};
`;

const Divider = styled.div`
    border-bottom: 1px solid ${Color.Neutral300};
    margin: 10px 0;
`;

const NotesFont = styled(Paragraph3)`
    white-space: pre-line;
`;
