export const contractTabI18n = {
    'contract-tab.details': 'Details',
    'contract-tab.schedule': 'Billing schedule',
    'contract-tab.invoice-entries': 'Invoice entries',
    'contract-tab.assignments': 'Assignments',
    'contract-tab.rental-contracts': 'Rental contracts',
    'contract-tab.addons': 'Addons',
    'contract-tab.repayment-plans': 'Repayment plans',
    'contract-tab.storage-assignments': 'Storage assignments',
};
