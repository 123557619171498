import React, { FC } from 'react';

import { emptyUserPayload } from '@hofy/api-admin';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { Box, Button, Form, SubmitButton, SvgIcon } from '@hofy/ui';

import { UserForm } from '../../../components/domain/user/UserForm';
import { useCreateUser } from '../../../store/users/useCreateUser';
import { useUserForm } from '../../../store/users/useUserForm';

interface CreateUserSlideoutProps {
    organizationId: number;
    onClose(): void;
}

export const CreateUserSlideout: FC<CreateUserSlideoutProps> = ({ organizationId, onClose }) => {
    const { createUser, isCreateUserLoading } = useCreateUser(onClose);

    const form = useUserForm(
        emptyUserPayload,
        null,
        payload => {
            createUser({
                organizationId: organizationId,
                ...payload,
            });
        },
        onClose,
    );

    return (
        <Slideout width={600} onClose={form.discard}>
            <Form onSubmit={form.submit} isLoading={isCreateUserLoading} flex='auto' column>
                <SlideoutHeader title='Add new user' />
                <SlideoutContent>
                    <UserForm form={form} organizationId={organizationId} />
                </SlideoutContent>
                <SlideoutFooter>
                    <Button
                        type='ghost'
                        negativeMargin
                        onClick={form.discard}
                        label='Cancel'
                        leftIcon={SvgIcon.Cross}
                    />
                    <Box row>
                        <SubmitButton label='Save' />
                    </Box>
                </SlideoutFooter>
            </Form>
        </Slideout>
    );
};
