import { groupBy, values } from 'lodash';
import { useMemo, useState } from 'react';

import { ProductDto, VariantDto } from '@hofy/api-admin';
import { useSearch } from '@hofy/hooks';

type ProductAndVariant = [ProductDto, VariantDto];

export const useProductSearch = (products: ProductDto[]) => {
    const [search, setSearch] = useState('');

    const flatProducts = useMemo(
        () => products.flatMap(p => p.variants.map(v => [p, v] as ProductAndVariant)),
        [products],
    );

    const searchResults = useSearch(
        flatProducts,
        ([product, variant]) => [product.name, product.brand, variant.sku],
        search,
    );

    const finalProducts: ProductDto[] = useMemo(() => {
        const productMap = groupBy(searchResults, p => p[0].id);
        return values(productMap).map(product => {
            const allVariants = product.map(p => p[1]);
            const filteredVariants = allVariants.filter(v => v.sku.toLowerCase() === search.toLowerCase());
            return {
                ...product[0][0],
                variants: filteredVariants.length > 0 ? filteredVariants : allVariants,
            };
        });
    }, [searchResults, search]);

    return {
        products: finalProducts,
        search,
        setSearch,
    };
};
