import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    OrganizationDetailsDto,
    organizationService,
    UpdateOrganizationFinancialSettingsPayload,
} from '@hofy/api-admin';
import { isPriceGreaterThan, isRequired, useForm, useToast, validator } from '@hofy/ui';

import { organizationsCacheKey } from './organizationsCacheKey';

export const useUpdateOrganizationFinancialSettings = (
    organization: OrganizationDetailsDto,
    onSuccess?: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: UpdateOrganizationFinancialSettingsPayload) =>
            organizationService.updateOrganizationFinancialSettings(organization.idDeprecated, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey] });
            showToast({
                message: `Organization ${organization!.name} financial settings successfully updated`,
                type: 'positive',
            });
            onSuccess?.();
        },
    });

    const form = useForm<UpdateOrganizationFinancialSettingsPayload>({
        initial: {
            unbundlingEnabled: organization.unbundlingEnabled,
            creditLimit: organization.crediting.creditLimit,
            sassFee: organization.pricing.sassFee,
            laptopConfigFee: organization.pricing.laptopConfigFee,
            mdmFee: organization.pricing.mdmFee,
            allowedBillingFrequencies: organization.allowedBillingFrequencies,
            allowedRentalTerms: organization.allowedRentalTerms,
            platformTierConfig: organization.platformTierConfig,
            storefrontFee: organization.storefrontFee,
            allowedAcquisitionTypes: organization.allowedAcquisitionTypes,
            paymentType: organization.defaultPaymentType,
        },
        onSubmit: mutation.mutate,
        validate: validator<UpdateOrganizationFinancialSettingsPayload>({
            creditLimit: [isPriceGreaterThan('-1', 'Credit limit cannot be negative.')],
            sassFee: [
                isRequired('SAAS fee is required'),
                isPriceGreaterThan('-1', 'SAAS fee cannot be negative.'),
            ],
            laptopConfigFee: [isPriceGreaterThan('-1', 'Configuration fee cannot be negative.')],
            mdmFee: [isPriceGreaterThan('-1', 'MDM fee cannot be negative.')],
            storefrontFee: [isRequired('Organization storefront fee is required')],
        }),
    });

    return {
        form,
        isLoadingMutation: mutation.isPending,
    };
};
