import React, { FC, useMemo, useState } from 'react';

import { ProductDetailsDto, ProductPayload, VariantDetailsDto } from '@hofy/api-admin';
import { allRentalTerms, ImageDto, ProductCategory, RentalTerm } from '@hofy/api-shared';
import { Slideout, SlideoutContent, SlideoutHeader } from '@hofy/common';
import { RentalTermFormDropdown } from '@hofy/core';
import { zeroUuid } from '@hofy/global';
import { Button, SvgIcon, UseForm } from '@hofy/ui';

import { ProductPreviewDetails } from './ProductPreviewDetails';

interface ProductPreviewSlideoutProps {
    form: UseForm<ProductPayload>;
    onClose(): void;
}

export const ProductPreviewSlideout: FC<ProductPreviewSlideoutProps> = ({ form, onClose }) => {
    const [rentalTerm, setRentalTerm] = useState(RentalTerm.Rental6Months);
    const product = useMemo(() => formToProduct(form.values), [form.values]);

    return (
        <Slideout width={1150} onClose={onClose}>
            <SlideoutHeader justify='space-between'>
                <Button
                    type='ghost'
                    negativeMargin
                    label='Back'
                    leftIcon={SvgIcon.ChevronLeft}
                    onClick={onClose}
                />
                <RentalTermFormDropdown
                    onChange={setRentalTerm}
                    value={rentalTerm}
                    label='Contract option'
                    availableRentalTerms={allRentalTerms}
                    allowedRentalTerms={allRentalTerms}
                />
            </SlideoutHeader>
            <SlideoutContent>
                <ProductPreviewDetails rentalTerm={rentalTerm} product={product} />
            </SlideoutContent>
        </Slideout>
    );
};

const formToProduct = (payload: ProductPayload): ProductDetailsDto => {
    const images: ImageDto[] = payload.images
        .filter(image => image.isActive)
        .map((image, index) => ({
            url: image.url,
            position: index,
            productId: 0,
            isActive: image.isActive,
            id: image.id,
        }));

    const variants: VariantDetailsDto[] = payload.variants
        .filter(variant => variant.isActive)
        .map(
            (variant, _): VariantDetailsDto => ({
                id: zeroUuid,
                isActive: variant.isActive,
                sku: variant.sku,
                recommendedRetailPrice: variant.recommendedRetailPrice,
                manufacturerPartCode: variant.manufacturerPartCode ?? '',
                style: variant.style,
                size: variant.size,
                os: variant.os ?? null,
                price: variant.price,
                unbundledPrice: variant.unbundledPrice,
                purchasePrice: variant.purchasePrice,
                unbundledPurchasePrice: variant.unbundledPurchasePrice,
                image: images.find(image => image.id === variant.image)!,
                availability: variant.availability,
                isInternal: false,
                netsuite: null,
                isAvailableForStoreAndReuse: variant.isAvailableForStoreAndReuse,
                organization: null,
            }),
        );

    return {
        createdAt: null!,
        createdBy: null!,
        updatedAt: null!,
        updatedBy: null!,
        isActive: payload.isActive,
        isRefurbished: payload.isRefurbished,
        id: zeroUuid,
        brand: payload.brand ?? '',
        name: payload.name ?? '',
        description: payload.description ?? '',
        specifications: payload.specifications,
        isConsumable: payload.category === ProductCategory.WelcomePack,
        category: payload.category ?? ProductCategory.Desk,
        isInternal: false,
        images,
        variants,
        tier: payload.tier,
    };
};
