import { xor } from 'lodash';
import React, { FC } from 'react';

import { useAdminsQuery } from '@hofy/api-admin';
import {
    allOrganizationStatuses,
    allPlatformTiers,
    OrganizationStatus,
    Permission,
    PlatformTier,
    Role,
    useTrOrganizationStatus,
} from '@hofy/api-shared';
import { ExportButton, FormNumberInput, Page } from '@hofy/common';
import { coreCurrencies, Currency } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Box, Button, FilterChipList, PageHeader, Paragraph3, SearchInput, SvgIcon } from '@hofy/ui';
import { PermissionWrapper } from '@hofy/ui-domain';

import { BlockFilterButton } from '../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../components/design/blockFilters/BlockFilterContainer';
import { EnumMultiBlockFilter } from '../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../components/design/blockFilters/hooks/useBlockFilters';
import { UsersBlockFilter } from '../../components/domain/user/UsersBlockFilter';
import { useOrganizations } from '../../store/organizations/useOrganizations';
import { useTrPlatformTier } from '../../store/organizations/useTrPlatformTier';
import { OrganizationsTable } from './OrganizationsTable';

interface OrganizationsPageProps {
    onOpenOrganization(id: number): void;
    onNewOrganization(): void;
}

export const OrganizationsPage: FC<OrganizationsPageProps> = ({ onOpenOrganization, onNewOrganization }) => {
    const {
        organizations,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
        search,
        setSearch,
        statuses,
        setStatuses,
        currencies,
        setCurrencies,
        accountManagers,
        setAccountManagers,
        salesReps,
        setSalesReps,
        platformTiers,
        setPlatformTiers,
        downloadContractReport,
        creditUtilisationFrom,
        setCreditUtilisationFrom,
        creditUtilisationTo,
        setCreditUtilisationTo,
        filterCount,
    } = useOrganizations();

    const { data: allAccountManagers } = useAdminsQuery(Role.SuccessAdmin, true);
    const { data: allSalesReps } = useAdminsQuery(Role.SalesAdmin, true);
    const trStatus = useTrOrganizationStatus();
    const trPlatformTier = useTrPlatformTier();
    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    return (
        <Page pageId='admin-organizations'>
            <PageHeader
                title='Organizations'
                rightSlot={
                    <>
                        <ExportButton onClick={downloadContractReport} />
                        <Box row gap={8}>
                            <Paragraph3 color={Color.Neutral700}>Credit utilisation:</Paragraph3>
                            <Box row gap={8} maxWidth={150}>
                                <FormNumberInput
                                    placeholder='From'
                                    nullable
                                    value={creditUtilisationFrom || null}
                                    onChange={v => setCreditUtilisationFrom(v)}
                                />
                                <FormNumberInput
                                    placeholder='To'
                                    nullable
                                    value={creditUtilisationTo || null}
                                    onChange={v => setCreditUtilisationTo(v)}
                                />
                            </Box>
                        </Box>
                        <SearchInput
                            value={search}
                            onChange={setSearch}
                            placeholder='Search organisations…'
                            autoFocus
                        />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                        <PermissionWrapper permission={Permission.AdminOrganizationCreate} tooltip>
                            <Button
                                leftIcon={SvgIcon.Add}
                                label='Add organisation'
                                onClick={onNewOrganization}
                            />
                        </PermissionWrapper>
                    </>
                }
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <EnumMultiBlockFilter<PlatformTier>
                    title='Platform Tiers'
                    selected={platformTiers || []}
                    onChange={setPlatformTiers}
                    items={allPlatformTiers}
                    renderItem={trPlatformTier}
                />
                <UsersBlockFilter
                    title='Account Managers'
                    selected={accountManagers}
                    onChange={setAccountManagers}
                    users={allAccountManagers}
                />
                <UsersBlockFilter
                    title='Sales Rep'
                    selected={salesReps}
                    onChange={setSalesReps}
                    users={allSalesReps}
                />
                <EnumMultiBlockFilter<OrganizationStatus>
                    title='Status'
                    selected={statuses || []}
                    onChange={setStatuses}
                    items={allOrganizationStatuses}
                    renderItem={trStatus}
                />
                <EnumMultiBlockFilter<Currency>
                    title='Currency'
                    selected={currencies || []}
                    onChange={setCurrencies}
                    items={coreCurrencies}
                    renderItem={v => v}
                />
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <FilterChipList<OrganizationStatus>
                    color='violet'
                    toKey={id => id}
                    selected={statuses || []}
                    toLabel={trStatus}
                    onClear={cleared => setStatuses(xor(statuses, [cleared]))}
                />
                <FilterChipList<Currency>
                    color='violet'
                    toKey={id => id}
                    selected={currencies || []}
                    toLabel={i => i}
                    onClear={cleared => setCurrencies(xor(currencies, [cleared]))}
                />
            </BlockFilterChipContainer>
            <OrganizationsTable
                isLoading={isLoading}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                fetchNextPage={fetchNextPage}
                organizations={organizations || []}
                onOpenOrganization={onOpenOrganization}
            />
        </Page>
    );
};
