import { filter, xor } from 'lodash';
import React, { FC, useMemo } from 'react';

import { SupplierRefDto } from '@hofy/api-shared';
import { Box, FilterChip } from '@hofy/ui';

interface SupplierFilterRendererProps {
    suppliers: SupplierRefDto[];
    supplierIds: number[];
    onChange(suppliers: number[]): void;
}

export const SupplierFilterRenderer: FC<SupplierFilterRendererProps> = ({
    suppliers,
    supplierIds,
    onChange,
}) => {
    const selectedSuppliers = useMemo(
        () => filter(suppliers, supplier => supplierIds.includes(supplier.id)),
        [suppliers, supplierIds],
    );

    const removeSupplier = (supplierId: number) => {
        onChange(xor(supplierIds, [supplierId]));
    };

    return (
        <Box row gap={10}>
            {selectedSuppliers.map(supplier => (
                <FilterChip
                    label={supplier.name}
                    onClear={() => removeSupplier(supplier.id)}
                    color='green'
                    key={supplier.id}
                />
            ))}
        </Box>
    );
};
