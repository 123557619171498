import React, { FC, useState } from 'react';

import { ProductDto, VariantDto } from '@hofy/api-admin';
import { SelectableList } from '@hofy/common';
import { NumberValues } from '@hofy/theme';
import { Box } from '@hofy/ui';

import { ProductRow } from './ProductRow';
import { VariantRow } from './VariantRow';

interface VariantPickerProps {
    products: ProductDto[];
    selected?: ProductAndVariant;
    onPick(v: ProductAndVariant): void;
}

export type ProductAndVariant = [ProductDto, VariantDto];

export const VariantPicker: FC<VariantPickerProps> = ({ products, onPick, selected }) => {
    const selectedVariant = selected ? selected[1] : undefined;
    const selectedProduct = selected ? selected[0] : undefined;
    const [subProduct, setSubProduct] = useState<ProductDto | undefined>();

    const clickVariant = (p: ProductDto, v: VariantDto) => {
        setSubProduct(undefined);
        onPick([p, v]);
    };

    const selectProduct = (v: ProductDto) => {
        if (v.variants.length > 1) {
            setSubProduct(v);
        } else {
            onPick([v, v.variants[0]]);
        }
    };

    if (subProduct) {
        return (
            <Box column flex='auto'>
                <ProductRow product={subProduct} onClick={() => setSubProduct(undefined)} borderBottom />
                <SelectableList flex='auto' overflow='auto' itemLeftSpacing={80 as NumberValues}>
                    {subProduct.variants.map(v => (
                        <VariantRow
                            key={v.id}
                            variant={v}
                            onClick={() => clickVariant(subProduct, v)}
                            selected={v.id === selectedVariant?.id}
                            inactive={!v.isActive}
                        />
                    ))}
                </SelectableList>
            </Box>
        );
    }

    return (
        <SelectableList flex='auto' overflow='auto'>
            {products.map(p => (
                <ProductRow
                    key={p.id}
                    product={p}
                    onClick={() => selectProduct(p)}
                    selectedVariant={selectedVariant}
                    selected={p.id === selectedProduct?.id}
                />
            ))}
        </SelectableList>
    );
};
