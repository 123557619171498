import { useQuery } from '@tanstack/react-query';

import { subscriptionService } from '../service/subscriptionService';
import { subscriptionCacheKey } from './cacheKey';

export const useDefaultSubscription = (organizationId: number) => {
    const { data, isLoading } = useQuery({
        queryKey: [subscriptionCacheKey, organizationId, 'default'],
        queryFn: () => subscriptionService.getDefaultSubscription(organizationId),
    });

    return { data, isLoading };
};
