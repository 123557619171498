import React, { FC, useState } from 'react';

import { ProductDto } from '@hofy/api-admin';
import { BlockLoader } from '@hofy/common';
import { Box, Button, Modal, ModalContent, ModalFooter, ModalHeader, Paragraph3, Span } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { ProductPicker } from '../../../components/domain/products/productPicker/ProductPicker';
import { useProductsByCategory } from '../../../store/assignments/useProductsByCategory';

interface ReplaceProductModalProps {
    product: ProductDto;
    onReplace(v: ProductDto): void;
    onForceReplacement(): void;
    sendNotificationConfig: boolean;
    onCancel(): void;
}

export const ReplaceProductModal: FC<ReplaceProductModalProps> = ({
    onForceReplacement,
    onReplace,
    onCancel,
    sendNotificationConfig,
    product,
}) => {
    const { products, isLoading } = useProductsByCategory(product.category, true, undefined, product.id);
    const [selectedProduct, setSelectedProduct] = useState<ProductDto>();

    return (
        <Modal onClose={onCancel} width={800}>
            <ModalHeader
                title={`Deactivating ${product.name}`}
                subtitle='You are deactivating a product which is currently used in user budgets. Please select a replacement'
                borderBottom
            />
            <ModalContent>
                {isLoading ? (
                    <Box centered height={500}>
                        <BlockLoader />
                    </Box>
                ) : (
                    <ProductPicker
                        products={products}
                        onPick={setSelectedProduct}
                        selected={selectedProduct}
                    />
                )}
            </ModalContent>
            <ModalFooter borderTop>
                <CancelButton onClick={onCancel} />
                <Box flex={1} row justify='center'>
                    <Paragraph3>
                        Sending notification is
                        <Span bold>{sendNotificationConfig ? ' enabled' : ' disabled'}</Span>
                    </Paragraph3>
                </Box>
                <Button
                    action='destructive'
                    label='Force deactivation'
                    onClick={onForceReplacement}
                    marginRight={20}
                />
                <Button
                    disabled={!selectedProduct}
                    label='Replace'
                    onClick={() => onReplace(selectedProduct!)}
                />
            </ModalFooter>
        </Modal>
    );
};
