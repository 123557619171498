import { useQuery } from '@tanstack/react-query';

import { suppliersService } from '../service/suppliersService';
import { AdminSuppliersFilters } from '../service/types/AdminSuppliersFilters';
import { suppliersCacheKey } from './cacheKey';

export const useSuppliersQuery = (filters?: AdminSuppliersFilters) => {
    const { data, isLoading } = useQuery({
        queryKey: [
            suppliersCacheKey,
            filters?.search,
            filters?.country,
            filters?.statuses,
            filters?.types,
            filters?.subsidiaries,
            filters?.netsuiteStatuses,
        ],

        queryFn: () => suppliersService.listSuppliers(filters),
    });
    return {
        isLoading,
        data: data || [],
    };
};
