import { omit } from 'lodash';

import { AdminPaymentsFilters } from '@hofy/api-admin';
import { NetsuiteSyncStatus, PaymentProvider } from '@hofy/api-shared';
import { countObjectValues } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import { useArrayQueryParam, useDateRangeQueryParam, useStringQueryParam } from '@hofy/router';

export const usePaymentsFilters = () => {
    const [search, setSearch] = useStringQueryParam<string>('search', '');

    const [dateRange, setDateRange] = useDateRangeQueryParam('dateRange');

    const [hofySubsidiaryIds, setHofySubsidiaryIds] = useArrayQueryParam<number>('hofySubsidiaryIds', []);
    const [organizationIds, setOrganizationIds] = useArrayQueryParam<number>('organizationIds', []);
    const [billingEntityIds, setBillingEntityIds] = useArrayQueryParam<number>('billingEntityIds', []);

    const [netsuiteStatuses, setNetsuiteStatuses] = useArrayQueryParam<NetsuiteSyncStatus>(
        'netsuiteStatuses',
        [],
    );
    const [paymentProviders, setPaymentProviders] = useArrayQueryParam<PaymentProvider>('provider', []);

    const filters = useStructMemo<AdminPaymentsFilters>({
        search,
        dateRange,
        hofySubsidiaryIds,
        organizationIds,
        billingEntityIds,
        netsuiteStatuses,
        paymentProviders,
    });

    const filtersToCount = omit(filters, 'search');
    const filterCount = countObjectValues(filtersToCount);

    return {
        search,
        filters,
        filterCount,
        setSearch,
        setDateRange,
        setHofySubsidiaryIds,
        setOrganizationIds,
        setBillingEntityIds,
        setNetsuiteStatuses,
        setPaymentProviders,
    };
};
