import { useMutation } from '@tanstack/react-query';

import { dataSeedService, emptySeedRandomOrdersPayload, SeedRandomOrdersPayload } from '@hofy/api-admin';
import { isGreaterThan, isRequired, useForm, useToast, validator } from '@hofy/ui';

export const useSeedRequests = () => {
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: dataSeedService.seedRandomOrders,
        onSuccess: async () => {
            showToast({
                type: 'positive',
                message: 'Requests generated',
            });
        },
    });

    const form = useForm<SeedRandomOrdersPayload>({
        initial: emptySeedRandomOrdersPayload,
        onSubmit: mutation.mutate,
        validate: validator<SeedRandomOrdersPayload>({
            organizationId: [isRequired('Organization is required'), isGreaterThan(0, 'Count must be valid')],
        }),
    });
    return { form, isLoading: mutation.isPending };
};
