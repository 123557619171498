import React from 'react';

import { InspectionDetailsUnionDto } from '@hofy/api-admin';
import { formatVariant, useTrOwnership } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { FormGridRow, FormSection, Labeled, LabeledText, Paragraph3 } from '@hofy/ui';

import { ProductItem } from '../../../components/domain/products/ProductItem';
import { Link } from '../../../components/routing/Link';
import { ProductDetailsLink } from '../../productsPage/ProductDetailsLink';
import { useInspectionSlideoutLinks } from './InspectionSlideoutContext';

interface ItemDetailsSectionProps {
    inspection: InspectionDetailsUnionDto;
}

export const ItemDetailsSection: React.FC<ItemDetailsSectionProps> = ({ inspection }) => {
    const trOwnership = useTrOwnership();
    const { getItemLink } = useInspectionSlideoutLinks();

    return (
        <FormSection label='Item details'>
            <ProductItem
                image={inspection.variant.image}
                label={
                    inspection.product.isControlledByOrganization ? (
                        inspection.product.name
                    ) : (
                        <ProductDetailsLink id={inspection.product.id}>
                            <Paragraph3 color={Color.ContentPrimary}>{inspection.product.name}</Paragraph3>
                        </ProductDetailsLink>
                    )
                }
                subLabel={formatVariant(inspection.variant)}
            />
            <FormGridRow columns={4}>
                <Labeled
                    label='Item Id'
                    content={
                        <Link to={getItemLink(inspection.item.id)} color={Color.ContentPrimary}>
                            <Paragraph3 color={Color.ContentPrimary}>#{inspection.item.id}</Paragraph3>
                        </Link>
                    }
                />
                <LabeledText label='Item serial number' content={inspection.item.serialNumber ?? '--'} />
                <LabeledText
                    label='Item codes'
                    content={inspection.itemCodes.map(({ code }) => code).join(', ') ?? '--'}
                />
                <LabeledText label='Item ownership' content={trOwnership(inspection.item.ownership)} />
            </FormGridRow>
        </FormSection>
    );
};
