import React, { FC, useState } from 'react';

import { Currency } from '@hofy/global';
import { Alert, AsyncButton, Box, Button, Modal, ModalContent, ModalFooter, ModalHeader } from '@hofy/ui';

import { useUpdateContractBillingEntity } from '../../../../store/contracts/useUpdateContractBillingEntity';
import { BillingEntityDropdown } from '../../../invoicingPage/invoiceEntries/components/BillingEntityDropdown';

interface BillingEntityUpdateModalProps {
    contractId: number;
    organizationId: number;
    currentBillingEntity: BillingEntityData;
    onClose(): void;
}

interface BillingEntityData {
    id: number;
    currency: Currency;
}

export const BillingEntityUpdateModal: FC<BillingEntityUpdateModalProps> = ({
    contractId,
    organizationId,
    currentBillingEntity,
    onClose,
}) => {
    const { updateBillingEntity, isLoading, isError } = useUpdateContractBillingEntity(contractId, onClose);
    const [newBillingEntity, setNewBillingEntity] = useState<BillingEntityData>(currentBillingEntity);
    const changeBillingEntity = (id?: number, currency?: Currency) => {
        if (id !== undefined && currency !== undefined) {
            setNewBillingEntity({ id, currency });
        }
    };
    return (
        <Modal width={600} onClose={onClose}>
            <ModalHeader title='Update billing entity' />
            <ModalContent>
                <Box column gap={16}>
                    <Alert
                        type='informative'
                        description={
                            "A purchase contract's billing entity cannot be changed if it has already been invoiced, or if any invoice entries were sold to Two."
                        }
                    />
                    {newBillingEntity.currency !== currentBillingEntity.currency && (
                        <Alert
                            type='warning'
                            description={`The selected billing entity uses a different currency. The purchase contract price and invoice entries will be converted from ${currentBillingEntity.currency} to ${newBillingEntity.currency} using the current exchange rate.`}
                        />
                    )}
                    <BillingEntityDropdown
                        organizationId={organizationId}
                        onChange={(_, b) => changeBillingEntity(b?.idDeprecated, b?.currency)}
                        label='New billing entity'
                        value={newBillingEntity.id}
                    />
                </Box>
            </ModalContent>
            <ModalFooter>
                <Button type='secondary' label='Cancel' onClick={onClose} />
                <AsyncButton
                    label='Update'
                    isLoading={isLoading}
                    isError={isError}
                    onClick={() => updateBillingEntity(newBillingEntity.id)}
                    disabled={newBillingEntity.id === currentBillingEntity.id}
                />
            </ModalFooter>
        </Modal>
    );
};
