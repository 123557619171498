import React, { FC, useState } from 'react';

import {
    ContractFiltersUi,
    EnableStoreAndReuseDeliveryPayload,
    StoreAndReuseStatusFilter,
    useContractsQuery,
    useFulfilmentCountrySettingQuery,
} from '@hofy/api-admin';
import { ProductCategory } from '@hofy/api-shared';
import { Country, UUID } from '@hofy/global';
import { Box, ConfirmModal, SearchInput, UseForm } from '@hofy/ui';

import { useEnableStoreAndReuseDelivery } from '../../../store/assignments/useEnableStoreAndReuseDelivery';
import { ContractRadioList } from './ContractRadioList';

interface EnableStoreAndReuseDeliveryModalProps {
    assignmentId: number;
    organizationId: number;
    productId: UUID;
    productCategory: ProductCategory;
    contractId: number;
    country: Country;
    onSuccess(): void;
}

export const EnableStoreAndReuseDeliveryModal: FC<EnableStoreAndReuseDeliveryModalProps> = ({
    assignmentId,
    organizationId,
    productId,
    productCategory,
    contractId,
    country,
    onSuccess,
}) => {
    const { form } = useEnableStoreAndReuseDelivery(assignmentId, onSuccess);

    return (
        <ConfirmModal
            width={800}
            keyPrefix='enable-store-and-reuse-delivery-modal'
            onClose={onSuccess}
            onConfirm={form.submit}
            disabled={!form.values.contractId}
        >
            <ModalContent
                form={form}
                organizationId={organizationId}
                productId={productId}
                productCategory={productCategory}
                contractId={contractId}
                country={country}
            />
        </ConfirmModal>
    );
};

interface ModalContentProps {
    form: UseForm<EnableStoreAndReuseDeliveryPayload>;
    organizationId: number;
    productId: UUID;
    productCategory: ProductCategory;
    contractId: number;
    country: Country;
}

const ModalContent: FC<ModalContentProps> = ({
    form,
    organizationId,
    productId,
    productCategory,
    contractId,
    country,
}) => {
    const [search, setSearch] = useState('');
    const { data: countrySetting } = useFulfilmentCountrySettingQuery(country);
    const defaultWarehouse = countrySetting?.defaultDeliverFromWarehouse;

    const filters: ContractFiltersUi = {
        organizations: [organizationId],
        storeAndReuseStatus: [StoreAndReuseStatusFilter.ReadyToReuse],
        storeAndReuseDeliveryCountry: country ?? null,
        search,
        status: [],
        tab: null,
        contractType: [],
        redistributable: null,
        productId: null,
        productCategory: null,
    };

    const sameProduct = useContractsQuery({
        ...filters,
        productId,
    });
    const similarProducts = useContractsQuery({
        ...filters,
        productCategory,
    });

    const filteredSameProduct = sameProduct.contracts.filter(contract => contract.id !== contractId);
    const sameProductIds = filteredSameProduct.map(contract => contract.product.id);

    const filteredSimilarProducts = similarProducts.contracts.filter(
        contract => contract.id !== contractId && !sameProductIds.includes(contract.product.id),
    );

    return (
        <>
            <SearchInput value={search} onChange={setSearch} placeholder='Find contract Id…' marginTop={30} />

            <Box maxHeight={400} overflow='auto' paddingBottom={20}>
                <ContractRadioList
                    title='Same product'
                    contracts={filteredSameProduct}
                    isLoading={sameProduct.contractsIsLoading}
                    hasNextPage={sameProduct.hasNextPage}
                    selectedId={form.values.contractId}
                    onSelect={contractId =>
                        form.setValues({
                            contractId,
                        })
                    }
                    onFetchNextPage={() => sameProduct.fetchNextPage()}
                    warehouse={defaultWarehouse}
                />

                <ContractRadioList
                    title='Similar products'
                    contracts={filteredSimilarProducts}
                    isLoading={similarProducts.contractsIsLoading || similarProducts.isFetchingNextPage}
                    hasNextPage={similarProducts.hasNextPage}
                    selectedId={form.values.contractId}
                    onSelect={contractId =>
                        form.setValues({
                            contractId,
                        })
                    }
                    onFetchNextPage={() => similarProducts.fetchNextPage()}
                    warehouse={defaultWarehouse}
                />
            </Box>
        </>
    );
};
