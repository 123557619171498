import React, { FC, useState } from 'react';

import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { ComponentLoader, useSearch } from '@hofy/common';
import { formatDate, isDateInFuture } from '@hofy/helpers';
import {
    Alert,
    BaseTable,
    BooleanBadge,
    Box,
    ConfirmModal,
    IconButton,
    Link,
    Modals,
    SvgIcon,
} from '@hofy/ui';

import { useDeleteOrganizationTermAndCondition } from '../../../store/organizations/useDeleteOrganizationTermAndCondition';
import { useOrganizationTermAndConditionsQuery } from '../../../store/organizations/useOrganizationTermAndConditionsQuery';

interface TermsAndConditionsTabProps {
    organizationId: number;
    search: string;
}

export const TermsAndConditionsTab: FC<TermsAndConditionsTabProps> = ({ organizationId, search }) => {
    const [deleteTermAndConditionId, setDeleteTermAndConditionId] = useState<number | null>(null);

    const { data: organizationTermAndConditions, isLoading } =
        useOrganizationTermAndConditionsQuery(organizationId);
    const { results: filteredTACs } = useSearch(organizationTermAndConditions, t => [t.name], search);

    const { hasPermission } = useSession();

    const { deleteOrganizationTermAndCondition } = useDeleteOrganizationTermAndCondition();

    const deleteOrganizationTermAndConditionHandler = () => {
        if (deleteTermAndConditionId !== null) {
            deleteOrganizationTermAndCondition(deleteTermAndConditionId);
        }
    };

    if (isLoading) {
        return (
            <Box relative fullHeight>
                <ComponentLoader />
            </Box>
        );
    }
    return (
        <>
            <Alert
                type='informative'
                marginVertical={10}
                marginHorizontal={20}
                description='Rules to detect the currently active terms and conditions which would be applied for newly-created contracts.'
            />
            <BaseTable
                data={filteredTACs}
                toKey={tac => tac.id}
                columns={[
                    {
                        id: 'createdAt',
                        header: 'Created at',
                        flexGrow: 0,
                        width: 200,
                        renderer: tac => formatDate(tac.createdAt),
                    },
                    {
                        id: 'name',
                        header: 'Name',
                        flexGrow: 1,
                        renderer: tac => tac.name,
                    },
                    {
                        id: 'url',
                        header: 'Url',
                        flexGrow: 1,
                        renderer: ({ url }) => (url ? <Link to={url}>{url}</Link> : '--'),
                    },
                    {
                        id: 'startsAt',
                        header: 'Starts At',
                        flexGrow: 1,
                        renderer: tac => formatDate(tac.startOn),
                    },
                    {
                        id: 'currentlyActive',
                        header: 'Currently active',
                        flexGrow: 1,
                        renderer: tac => <BooleanBadge value={tac.currentlyActive} />,
                    },
                    {
                        id: 'delete',
                        header: '',
                        flexShrink: 0,
                        width: 30,
                        flexGrow: 0,
                        renderer: tac => {
                            if (!hasPermission(Permission.AdminOrganizationUpdateFinancialSettings)) {
                                return null;
                            }
                            return (
                                <Box flex={1} row justify='flex-end'>
                                    <IconButton
                                        disabled={!(tac.startOn && isDateInFuture(tac.startOn))}
                                        icon={SvgIcon.Trash}
                                        onClick={() => setDeleteTermAndConditionId(tac.id)}
                                    />
                                </Box>
                            );
                        },
                    },
                ]}
            />
            <Modals>
                {!!deleteTermAndConditionId && (
                    <ConfirmModal
                        keyPrefix='delete-organization-term-and-condition-modal'
                        onClose={() => setDeleteTermAndConditionId(null)}
                        onConfirm={deleteOrganizationTermAndConditionHandler}
                    />
                )}
            </Modals>
        </>
    );
};
