import { useMutation } from '@tanstack/react-query';

import { authService } from '@hofy/api-admin';
import { APP_CONFIG } from '@hofy/config';
import { stringifyUrl } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useSignInToOrganization = () => {
    const { showErrorToast } = useToast();
    const mutation = useMutation({
        mutationFn: authService.signInToOrganization,
        onSuccess: r => {
            window.open(
                stringifyUrl({
                    url: `${APP_CONFIG.hofyUrl!}/set-token`,
                    query: {
                        accessToken: r.access_token,
                        refreshToken: r.refresh_token,
                    },
                }),
                '_blank',
            );
        },
        onError: () => showErrorToast(),
    });

    return {
        signInToOrganization: (id: number) => mutation.mutate(id),
        isLoadingMutation: mutation.isPending,
    };
};
