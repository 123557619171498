import React, { FC } from 'react';

import { CountryMultiFormDropdown } from '@hofy/core';
import { Alert, Box, Form, LabeledDateInput, LabeledInput, SubmitButton } from '@hofy/ui';

import { LabeledOrganizationSelect } from '../../../../components/domain/organizations/LabeledOrganizationSelect';
import { useSeedNonHofyAssets } from '../../../../store/settings/useSeedNonHofyAssets';

export const SeedRandomNonHofyAssets: FC = () => {
    const { form, isLoading } = useSeedNonHofyAssets();

    return (
        <Box>
            <Form column gap={20} onSubmit={form.submit} isLoading={isLoading}>
                <Alert
                    type='informative'
                    description='Creates Non-Hofy owned assets, optionally in the given countries (users must already exist in these countries)'
                />
                <LabeledOrganizationSelect
                    label='Organization'
                    onChange={(organizationId: number) => form.setValues({ organizationId })}
                    value={form.values.organizationId}
                    placeholder='Choose organization'
                    nullable={false}
                    errorMessage={form.currentErrors.organizationId?.toString()}
                    isRequired
                />
                <LabeledInput
                    label='Number of assets'
                    type='number'
                    onChange={numberOfAssets => form.setValues({ numberOfAssets: parseInt(numberOfAssets) })}
                    value={form.values.numberOfAssets.toString()}
                    placeholder='Enter number of contracts'
                    errorMessage={form.currentErrors.numberOfAssets?.toString()}
                    isRequired
                />
                <LabeledDateInput
                    label='Active from date:'
                    nullable={false}
                    value={form.values.activeOnRange.start}
                    onChange={start =>
                        form.setValues({ activeOnRange: { start, end: form.values.activeOnRange.end } })
                    }
                    errorMessage={form.currentErrors.activeOnRange?.toString()}
                    isRequired
                />
                <LabeledDateInput
                    label='Active to date:'
                    nullable={false}
                    value={form.values.activeOnRange.end}
                    onChange={end =>
                        form.setValues({ activeOnRange: { start: form.values.activeOnRange.start, end } })
                    }
                    errorMessage={form.currentErrors.activeOnRange?.toString()}
                    isRequired
                />
                <CountryMultiFormDropdown
                    label='Countries'
                    value={form.values.countries}
                    onChange={countries =>
                        form.setValues({
                            countries,
                        })
                    }
                />
                <SubmitButton label='Generate' />
            </Form>
        </Box>
    );
};
