import React, { FC } from 'react';

import { TermAndConditionDto } from '@hofy/api-admin';
import { nowISODate } from '@hofy/helpers';
import {
    Box,
    FormContainer,
    FormDateInput,
    FormModal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    SubmitButton,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useUpdateContractsTermAndCondition } from '../../../store/contracts/useUpdateContractsTermAndCondition';
import { TermAndConditionFormSelect } from './TermAndConditionFormSelect';

interface ApplyRetrospectivelyTermAndConditionModalProps {
    onClose(): void;
    organizationId: number;
    termsAndConditions: TermAndConditionDto[];
}

export const ApplyRetrospectivelyTermAndConditionModal: FC<
    ApplyRetrospectivelyTermAndConditionModalProps
> = ({ organizationId, termsAndConditions, onClose }) => {
    const defaultTermAndCondition = termsAndConditions.find(t => t.isDefault) || termsAndConditions[0];

    const { form, isLoading, isError } = useUpdateContractsTermAndCondition(
        organizationId,
        defaultTermAndCondition,
        onClose,
    );

    return (
        <FormModal
            onSubmit={form.submit}
            onClose={form.discard}
            isLoading={isLoading}
            isError={isError}
            width={550}
        >
            <ModalHeader title='Apply retrospectively' borderBottom />
            <ModalContent>
                <FormContainer>
                    <TermAndConditionFormSelect
                        termsAndConditions={termsAndConditions}
                        api={form.fields.termAndCondition}
                    />
                    <Box row gap={24}>
                        <FormDateInput
                            label='Contracts created at (start date)'
                            api={form.fields.contractsCreatedOnStart}
                            maxDate={nowISODate()}
                            nullable
                        />
                        <FormDateInput
                            label='Contracts created at (end date)'
                            api={form.fields.contractsCreatedOnEnd}
                            maxDate={nowISODate()}
                            nullable
                        />
                    </Box>
                </FormContainer>
            </ModalContent>
            <ModalFooter borderTop>
                <CancelButton onClick={form.discard} />
                <SubmitButton label='Apply' />
            </ModalFooter>
        </FormModal>
    );
};
