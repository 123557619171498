export enum AssignmentError {
    MissingAssetValueForToken = 'missing_asset_value_for_token',
    MissingFeeForBill = 'missing_fee_for_bill',
    MissingInvoiceAndTokenForBill = 'missing_invoice_or_token_for_bill',
    MissingFulfillmentEntityForBill = 'missing_fulfillment_entity',
    InvalidAssignmentStatus = 'invalid_assignment_status',
    InvalidAssignmentNotALoaner = 'cannot_convert_assignment_to_replacement_if_not_a_loaner',
    InvalidAssignmentWithoutItem = 'cannot_convert_assignment_to_replacement_if_no_item_is_assigned',
    AssignmentIsConsumable = 'assignment_is_consumable',
    AssignmentIsALoaner = 'assignment_is_a_loaner',
    AssignmentIsNotARental = 'assignment_is_not_a_rental',
    AssignmentNotWithUser = 'assignment_not_with_user',
    AssignmentWithoutItem = 'assignment_without_item',
    AssignmentContractHasMoreActiveAssignments = 'assignment_contract_has_more_active_assignments',
    AssignmentIsFromPartnerOrganization = 'assignment_is_from_partner_organization',
    AssignmentWithOrganizationOwnedItem = 'assignment_has_organization_owned_item_attached',
    AssignmentWithManagementContract = 'assignment_has_management_contract_attached',
    AssignmentHasNoItem = 'assignment_has_no_assigned_item',
    ItemDoesNotMatchAssignedItem = 'item_does_not_match_assigned_item',
    AssignmentItemIsScanned = 'assignment_item_is_scanned',
    AssignmentIsNotARedistribution = 'assignment_is_not_a_redistribution',
    AssignmentItemIsNotScanned = 'assignment_item_is_not_scanned',
    AssignmentHasBeenShipped = 'assignment_has_been_shipped',
    AssignmentIsNotFinal = 'assignment_is_not_final',
    AssignmentNotFound = 'assignment_not_found',
    MissingItem = 'missing_item',
    AssignmentIsSubstituted = 'assignment_is_being_replaced',
    ContractAndAssignmentOrganizationMismatch = 'contract_and_assignment_belong_to_different_organizations',
    ContractIsAlreadyBeingReused = 'contract_is_already_being_reused',
    ContractInactive = 'rental_agreement_is_not_active',
    ItemBelongsToOtherOrg = 'item_belongs_to_other_organization',
    ItemCannotBeUsedAsLoaner = 'item_cannot_be_used_as_loaner',
    ItemNotRedistributable = 'item_is_not_redistributable',
    ItemNotUsableInStoreAndReuse = 'item_is_not_reusable',
    ItemContractHasPendingAssignments = 'item_contract_has_pending_assignments',
    AssignmentIsLoaner = 'item_is_a_loaner_assignment',
    AssignmentWithoutContract = 'assignment_has_no_contract_attached',
    AssignmentIsDisposed = 'assignment_is_disposed',
    AdminDisposeCustomerOwnedAsset = 'hofy_cannot_dispose_customer_owned_assets',
    AssignmentIsUnconvertedRedistribution = 'assignment_is_unconverted_redistribution',
    InvalidItemLocation = 'invalid_item_location',
    AssignmentContractNotPending = 'assignment_contract_is_not_pending',
    AssignmentSubstitutionMissing = 'assignment_substitution_original_request_is_missing',
    AssignmentStatus = 'invalid_assignment_status',
    InvalidStoreAndReusePayload = 'invalid_payload_for_store_and_reuse_collection',
    CustomerUploadedItemCollectionIsNotStoreAndReuse = 'customer_uploaded_item_collection_is_not_store_and_reuse_error',
    AssignmentNotAStoreAndReuseCollection = 'assignment_is_not_a_store_and_reuse_collection',
    AssignmentNotAStoreAndReuseDelivery = 'assignment_is_not_a_store_and_reuse_delivery',
    AssignmentWithAttachedItemError = 'assignment_has_item_attached',
    RentalContractStatus = 'invalid_rental_contract_status',
    ContractStoreAndReuseStatusInvalidVariant = 'invalid_variant_to_reuse',
    AssignmentWithoutRentalContract = 'assignment_has_no_rental_contract_attached',
    AssignmentIsARedistribution = 'assignment_is_a_redistribution',
    ActiveRentalCannotBeReplace = 'cannot_substitute_active_rental_agreement',
    AssignmentConfigurationNotRequired = 'assignment_does_not_require_configuration',
    InvalidAssignmentConfigurationUpdate = 'assignment_status_is_not_delivery_pending',
    InvalidAssignmentStatusForStoreAndReuse = 'invalid_assignment_status_for_store_and_reuse',
    ItemAlreadyAssigned = 'item_already_attached_to_active_assignment',
    AssignmentHasDropshipShipment = 'assignment_has_dropship_shipment',
    AssignmentStatusNotPending = 'assignment_status_should_be_delivery_pending_or_collection_pending',
    ItemStillBeingInspected = 'item_still_being_inspected',
    ItemHasDamagedParts = 'item_has_damaged_parts',
    ItemHasMissingParts = 'item_has_missing_parts',
    DeviceIsLocked = 'device_is_locked',
    ActiveRentalItemsCannotBeTransferredBetweenTaxZones = 'active_rental_items_cannot_be_transferred_between_tax_zones',
    ConsumableOrLoanerAssignmentsCannotBeTransferred = 'consumable_or_loaner_assignments_cannot_be_transferred',
    InvalidTargetUser = 'invalid_target_user',
    UsersDoNotBelongToSameOrganization = 'users_do_not_belong_to_same_organization',
}
