import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    OrganizationDetailsDto,
    organizationService,
    UpdateOrganizationDetailsPayload,
} from '@hofy/api-admin';
import { isRequired, useForm, useToast, validator } from '@hofy/ui';

import { organizationsCacheKey } from './organizationsCacheKey';

export const useUpdateOrganizationDetails = (
    organization: OrganizationDetailsDto,
    onSuccess?: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: UpdateOrganizationDetailsPayload) =>
            organizationService.updateOrganizationDetails(organization.idDeprecated, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, organization.idDeprecated] });
            showToast({
                message: `Organization ${organization.name} details successfully updated`,
                type: 'positive',
            });
            onSuccess?.();
        },
    });

    const form = useForm<UpdateOrganizationDetailsPayload>({
        initial: {
            name: organization.name,
            size: organization.size,
            logoUrl: organization.logoUrl,
            salesAdminId: organization.salesAdminUser?.idDeprecated ?? null,
            successAdminId: organization.successAdminUser?.idDeprecated ?? null,
        },
        onSubmit: mutation.mutate,
        validate: validator<UpdateOrganizationDetailsPayload>({
            name: [isRequired('Organization name is required')],
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
    };
};
