import React, { FC, ReactNode } from 'react';

import { Link } from '../../components/routing/Link';
import { OrganizationTab } from '../../store/organizations/types/OrganizationTab';

interface OrganizationLinkProps {
    id: number;
    children?: ReactNode;
    tab?: OrganizationTab;
}

export const OrganizationLink: FC<OrganizationLinkProps> = ({
    id,
    children,
    tab = OrganizationTab.Details,
}) => {
    return <Link to={`/organizations/${id}/${tab}`}>{children}</Link>;
};

export const OrganizationLinkOptional: FC<Partial<OrganizationLinkProps>> = ({ id, children }) => {
    return id ? <OrganizationLink id={id}>{children}</OrganizationLink> : <>--</>;
};
