import { DateRangeStrings } from '@hofy/global';

export interface SeedRandomHofyAssetsPayload {
    organizationId: number;
    numberOfAssets: number;
    activeOnRange: DateRangeStrings | null;
    generateInvoices: boolean;
}

export const emptySeedRandomRentalContractsPayload: SeedRandomHofyAssetsPayload = {
    organizationId: 0,
    numberOfAssets: 0,
    activeOnRange: null,
    generateInvoices: false,
};
