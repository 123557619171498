import React, { FC } from 'react';

import { AuditableTable } from '@hofy/api-admin';

import { AssignmentDetailsLink } from '../assignmentsPage/AssignmentDetailsLink';
import { ContractLink } from '../contractsPage/ContractLink';
import { ItemDetailsLink } from '../itemsPage/ItemDetailsLink';
import { OrganizationLink } from '../organizationsPage/OrganizationDetailsLink';

interface AuditLogsDetailsEntityLinkProps {
    tableName?: string;
    tableId?: number;
}

export const AuditLogsDetailsEntityLink: FC<AuditLogsDetailsEntityLinkProps> = ({ tableName, tableId }) => {
    if (!tableName || !tableId) {
        return <>--</>;
    }

    const content = () => {
        return `#${tableId}`;
    };

    switch (tableName) {
        case AuditableTable.Assignment:
            return <AssignmentDetailsLink id={tableId}>{content()}</AssignmentDetailsLink>;
        case AuditableTable.Organization:
            return <OrganizationLink id={tableId}>{content()}</OrganizationLink>;
        case AuditableTable.Item:
            return <ItemDetailsLink id={tableId}> {content()}</ItemDetailsLink>;
        case AuditableTable.RentalContracts:
            return <ContractLink id={tableId}>{content()}</ContractLink>;
    }
    return <>--</>;
};
