import React, { FC } from 'react';

import { PostAddressView } from '@hofy/address';
import { ManagementContractDetailsDto } from '@hofy/api-admin';
import { usePrice } from '@hofy/hooks';
import { FormGridItem, FormGridRow, FormSection, Labeled, LabeledText } from '@hofy/ui';

import { CustomerAssetStorageLocationChip } from '../../../components/domain/contracts/CustomerStorageLocationChip';
import { BillingEntityLink } from '../../invoicingPage/billingEntities/BillingEntityLink';
import { ManagementContractStatusLabel } from './ManagementContractStatusLabel';

interface ManagementSectionProps {
    label: string;
    managementContract: ManagementContractDetailsDto;
}

export const ManagementSection: FC<ManagementSectionProps> = ({ label, managementContract }) => {
    const { formatPrice, formatMonthlyPrice } = usePrice();
    const {
        billingEntity,
        hofySubsidiary,
        activationFee,
        storageMonthlyFee,
        billingToAddress,
        collectionLocation,
    } = managementContract;
    return (
        <FormSection label={label}>
            <FormGridRow columns={4}>
                <LabeledText label='Invoice from' content={hofySubsidiary.name} />
                <LabeledText
                    label='Invoice to'
                    content={<BillingEntityLink billingEntity={billingEntity} />}
                />
                <LabeledText label='Activation fee' content={formatPrice(activationFee)} />
                <LabeledText label='Storage fee' content={formatMonthlyPrice(storageMonthlyFee)} />
            </FormGridRow>
            <FormGridRow columns={4}>
                <LabeledText
                    label='Customer invoice address'
                    content={<PostAddressView address={billingToAddress} />}
                />
                <Labeled
                    label='Storage location'
                    content={
                        <CustomerAssetStorageLocationChip storageLocation={collectionLocation} /> ?? '--'
                    }
                />
                <FormGridItem columns={2}>
                    <ManagementContractStatusLabel managementContract={managementContract} />
                </FormGridItem>
            </FormGridRow>
        </FormSection>
    );
};
