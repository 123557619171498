import React, { FC } from 'react';

import { ContractDetailsWithManagement } from '@hofy/api-admin';
import { useTrPaymentType } from '@hofy/api-shared';
import { Box, FormGridRow, FormSection, LabeledText } from '@hofy/ui';

import { AuditSection } from '../../../components/domain/auditingInline/AuditSection';
import { OrganizationLink } from '../../organizationsPage/OrganizationDetailsLink';
import { MainItemSection } from './components/MainItemSection';
import {
    ContractBillingAddressLabeledText,
    ContractBillingCountryLabel,
    ContractBudgetLabeledText,
    ContractProductDetails,
    ContractTermsAndConditionsLabeledText,
} from './ContractDetailsTab';
import { ManagementContractHistory } from './ManagementContractHistory';
import { ManagementSection } from './ManagementSection';

interface ContractDetailsTabProps {
    contract: ContractDetailsWithManagement;
}

export const ManagementContractDetailsTab: FC<ContractDetailsTabProps> = ({ contract }) => {
    const {
        idDeprecated,
        publicId,
        organization,
        product,
        variant,
        budgetConsumption,
        termAndCondition,
        managementContract,
    } = contract;
    const { billingToAddress } = managementContract;
    const trPaymentType = useTrPaymentType();

    return (
        <Box
            overflow='auto'
            fullHeight
            flex='auto'
            row
            alignItems='flex-start'
            gap={40}
            paddingHorizontal={40}
        >
            <Box flex={1} marginVertical={30} gap={30} column>
                <FormSection label='Details'>
                    <FormGridRow columns={4}>
                        <LabeledText label='Management id' content={idDeprecated} />
                        <LabeledText label='Management public id' content={publicId} />
                        <LabeledText
                            label='Organization'
                            content={
                                <OrganizationLink id={organization.id}>{organization.name}</OrganizationLink>
                            }
                        />
                    </FormGridRow>
                    <FormGridRow columns={4}>
                        <ContractBillingCountryLabel billingToAddress={billingToAddress} />
                        <ContractBillingAddressLabeledText billingToAddress={billingToAddress} />
                    </FormGridRow>
                    <ContractProductDetails product={product} variant={variant} />
                    <FormGridRow columns={4}>
                        <ContractTermsAndConditionsLabeledText termAndCondition={termAndCondition} />
                        <ContractBudgetLabeledText budgetConsumption={budgetConsumption} />
                        <LabeledText label='Payment type' content={trPaymentType(contract.paymentType)} />
                    </FormGridRow>
                </FormSection>
                <ManagementSection label='Management' managementContract={managementContract} />
                <FormSection label='Status history'>
                    <ManagementContractHistory managementContract={managementContract} />
                </FormSection>
                <AuditSection model={contract} />
            </Box>
            <Box flex={1} marginVertical={30} gap={30} column>
                <MainItemSection item={contract.mainItem} />
            </Box>
        </Box>
    );
};
