import { useMutation, useQueryClient } from '@tanstack/react-query';

import { itPoliciesCacheKey, ItPolicyPayload, itPolicyService } from '@hofy/api-admin';
import { isPolicyNameUsed, ItPolicyDto, TeamColor } from '@hofy/api-shared';
import { errorMap, isEmpty } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

export const useCreateItPolicy = (organizationId: number, itPolicies: ItPolicyDto[], onEnd: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (p: ItPolicyPayload) => itPolicyService.createItPolicy(organizationId, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [itPoliciesCacheKey] });
            showToast({
                type: 'positive',
                message: `The team has been created!`,
            });
            onEnd();
            form.reset();
        },
    });

    const form = useForm<ItPolicyPayload>({
        initial: {
            color: TeamColor.Red,
            name: '',
        },
        onSubmit: mutation.mutate,
        onDiscard: onEnd,
        validate: ({ name }) => ({
            name: errorMap(
                [isEmpty(name), 'Team name is required'],
                [isPolicyNameUsed(itPolicies, name), 'This name is already in use by another team'],
            ),
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
