import React, { FC } from 'react';

import {
    AcquisitionType,
    allParentProductCategories,
    CategoryContractSettingsConfigDto,
    PaymentSchema,
    useTrParentProductCategory,
} from '@hofy/api-shared';
import { FormDropdown, Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { ContractSettingsDropdown } from '@hofy/core';
import { Alert, AsyncButton, Box, FormContainer } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useOrganizationDetailsQuery } from '../../../store/organizations/useOrganizationDetailsQuery';
import { useUpdateContractSettings } from '../../../store/organizations/useUpdateContractSettings';

interface UpdateContractSettingsSlideoutProps {
    onClose(): void;
    organizationId: number;
    contractSettings: CategoryContractSettingsConfigDto;
    allowedBillingFrequencies: PaymentSchema[];
}

export const UpdateContractSettingsSlideout: FC<UpdateContractSettingsSlideoutProps> = ({
    onClose,
    organizationId,
    contractSettings,
    allowedBillingFrequencies,
}) => {
    const trParentProductCategory = useTrParentProductCategory();
    const { form, isLoadingMutation } = useUpdateContractSettings(organizationId, contractSettings, onClose);
    const { data: organization } = useOrganizationDetailsQuery(organizationId);

    return (
        <Slideout width={600} onClose={onClose}>
            <SlideoutHeader title='Edit contract setting' />
            <SlideoutContent paddingVertical={30}>
                <FormContainer>
                    <FormDropdown
                        disabled
                        items={allParentProductCategories}
                        label='Parent product category'
                        value={form.values.parentProductCategory}
                        labelFormatter={trParentProductCategory}
                        isError={form.errors.rentalTerm}
                        onChange={() => {}}
                    />
                    {form.values.acquisitionType === AcquisitionType.Rental && (
                        <Box column gap={20}>
                            <ContractSettingsDropdown
                                rentalTerm={form.values.rentalTerm}
                                paymentSchema={form.values.paymentSchema}
                                onChange={(rentalTerm, paymentSchema) => {
                                    form.setValues({
                                        rentalTerm,
                                        paymentSchema,
                                    });
                                }}
                                allowedBillingFrequencies={allowedBillingFrequencies}
                                allowedRentalTerms={organization?.allowedRentalTerms || []}
                                unbundlingEnabled={!!organization?.unbundlingEnabled}
                            />
                            <Alert
                                description='Billing frequency will be applied for equipment, service and warranty'
                                type='warning'
                            />
                        </Box>
                    )}
                </FormContainer>
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton onClick={onClose} label='Cancel' />
                <AsyncButton isLoading={isLoadingMutation} label='Save' onClick={form.submit} disableCheck />
            </SlideoutFooter>
        </Slideout>
    );
};
