import React, { FC, useState } from 'react';

import { Slideout, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { Color } from '@hofy/theme';
import {
    AsyncButton,
    Box,
    Button,
    Paragraph4,
    SearchInput,
    SectionTitle3,
    Skeleton,
    SvgIcon,
} from '@hofy/ui';

import { ProductOverview } from '../../../components/domain/products/ProductOverview';
import {
    ProductAndVariant,
    VariantPicker,
} from '../../../components/domain/products/productPicker/VariantPicker';
import { useProductSearch } from '../../../store/assignments/useProductSearch';
import { useSubstituteProduct } from '../../../store/assignments/useSubstituteProduct';

interface SubstituteProductSlideoutProps {
    orderId: number;
    basePath: string;
    onClose(): void;
}

export const SubstituteProductSlideout: FC<SubstituteProductSlideoutProps> = ({
    orderId,
    basePath,
    onClose,
}) => {
    const { isLoadingMutation, isLoading, products, substitute, order } = useSubstituteProduct(
        orderId,
        basePath,
    );
    const [variant, setVariant] = useState<ProductAndVariant>();
    const { products: finalProducts, search, setSearch } = useProductSearch(products);
    const product = order?.requestedProduct || order?.product;
    const content = () => {
        if (isLoading || !product) {
            return <Skeleton fullWidth />;
        }

        return (
            <>
                {product && (
                    <Box paddingHorizontal={60} borderBottom paddingBottom={20}>
                        <SectionTitle3 paddingVertical={20}>Requested product</SectionTitle3>
                        <ProductOverview imageSize={60} product={product} images={product.image} />
                    </Box>
                )}
                <Box borderBottom paddingBottom={20} flex='auto' column>
                    <SectionTitle3 paddingHorizontal={60} paddingVertical={20}>
                        Possible substitutions
                    </SectionTitle3>
                    <VariantPicker onPick={setVariant} products={finalProducts} selected={variant} />
                </Box>
            </>
        );
    };
    return (
        <Slideout width={600} onClose={onClose} slideoutId='substitute-product'>
            <SlideoutHeader title='Substitute product' justify='space-between' paddingRight={20}>
                <SearchInput value={search} onChange={setSearch} placeholder='Search product, SKU…' />
            </SlideoutHeader>
            <Box relative flex='auto' column>
                {content()}
            </Box>
            <SlideoutFooter>
                <Button
                    type='ghost'
                    negativeMargin
                    onClick={onClose}
                    label='Cancel'
                    leftIcon={SvgIcon.Cross}
                />
                <Box row gap={20} alignContent='flex-end'>
                    {product?.isRefurbished && (
                        <Paragraph4 color={Color.ContentWarning}>
                            Refurbished contracts should not be substituted
                        </Paragraph4>
                    )}
                    <AsyncButton
                        isLoading={isLoadingMutation}
                        label='Substitute'
                        disabled={!variant}
                        onClick={() => substitute(variant![1].id)}
                        disableCheck
                    />
                </Box>
            </SlideoutFooter>
        </Slideout>
    );
};
