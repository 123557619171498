import { useMutation, useQueryClient } from '@tanstack/react-query';

import { organizationService } from '@hofy/api-admin';
import { CategoryContractSettingsConfigDto } from '@hofy/api-shared';
import { useForm, useToast } from '@hofy/ui';

import { organizationsCacheKey } from './organizationsCacheKey';

export const useUpdateContractSettings = (
    organizationId: number,
    contractSettings: CategoryContractSettingsConfigDto,
    onSuccess: () => void,
) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const updateContractSettingsMutation = useMutation({
        mutationFn: (payload: CategoryContractSettingsConfigDto) =>
            organizationService.updateOrganizationContractSettings(organizationId, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, organizationId] });
            showToast({
                type: 'positive',
                message: `Organization rental term successfully saved`,
            });
            onSuccess?.();
        },
    });

    const mutate = (data: CategoryContractSettingsConfigDto) => {
        updateContractSettingsMutation.mutate(data);
    };

    const form = useForm<CategoryContractSettingsConfigDto>({
        initial: contractSettings,
        onSubmit: mutate,
    });

    return {
        form,
        isLoadingMutation: updateContractSettingsMutation.isPending,
    };
};
