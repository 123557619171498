import React, { FC } from 'react';

import { ComponentLoader, Slideout } from '@hofy/common';

import { useOrganizationDetailsQuery } from '../../../store/organizations/useOrganizationDetailsQuery';
import { UpdateContractSettingsSlideout } from './UpdateContractSettingsSlideout';

interface UpdateContractSettingsSlideoutOverlayProps {
    onClose(): void;
    organizationId: number;
    contractSettingId: number;
}

export const UpdateContractSettingsSlideoutOverlay: FC<UpdateContractSettingsSlideoutOverlayProps> = ({
    onClose,
    organizationId,
    contractSettingId,
}) => {
    const { data: organization, isLoading } = useOrganizationDetailsQuery(organizationId);

    const selectedContractSetting = organization?.defaultContractSettings.find(
        item => item.id === contractSettingId,
    );

    if (isLoading || !organization || !selectedContractSetting) {
        return (
            <Slideout width={600} onClose={onClose}>
                <ComponentLoader />
            </Slideout>
        );
    }

    return (
        <UpdateContractSettingsSlideout
            organizationId={organizationId}
            contractSettings={selectedContractSetting}
            allowedBillingFrequencies={organization.allowedBillingFrequencies}
            onClose={onClose}
        />
    );
};
