import { omit } from 'lodash';

import { OrderFilters } from '@hofy/api-admin';
import { OrderStatus } from '@hofy/api-shared';
import { countObjectValues } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import { useArrayQueryParam, useStringQueryParam } from '@hofy/router';

export const useOrdersFilter = () => {
    const [search, setSearch] = useStringQueryParam<string>('search', '');
    const [statuses, setStatuses] = useArrayQueryParam<OrderStatus>('statuses', []);
    const [organizations, setOrganizations] = useArrayQueryParam<number>('organizations', []);

    const filters = useStructMemo<OrderFilters>({
        search,
        statuses,
        organizations,
    });

    const filtersToCount = omit(filters, 'search');
    const filterCount = countObjectValues(filtersToCount);

    return {
        filters,
        filterCount,
        setSearch,
        setStatuses,
        setOrganizations,
    };
};
