import React, { FC, useState } from 'react';

import { AddressDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { ConfirmModal } from '@hofy/ui';

import { SelectAddress } from './SelectAddress';

interface AddressResolverModalProps {
    userAddress: AddressDto;
    deliveryAddress: AddressDto;
    keyPrefix: string;
    onClose(): void;
    onConfirm(selectedaddressId: UUID): void;
}

export const ConfirmWithAddressResolverModal: FC<AddressResolverModalProps> = ({
    userAddress,
    deliveryAddress,
    keyPrefix,
    onClose,
    onConfirm,
}) => {
    const [addressToDeliver, setAddressToDeliver] = useState(deliveryAddress);

    return (
        <ConfirmModal
            keyPrefix={keyPrefix}
            onConfirm={() => onConfirm(addressToDeliver.id)}
            onClose={onClose}
            width={userAddress.id === deliveryAddress.id ? 450 : 800}
        >
            <SelectAddress
                userAddress={userAddress}
                deliveryAddress={deliveryAddress}
                onSelect={setAddressToDeliver}
            />
        </ConfirmModal>
    );
};
