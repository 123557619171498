import { difference, keyBy } from 'lodash';
import React, { FC, useEffect, useMemo } from 'react';

import { AddressField } from '@hofy/address';
import { UpdateUserPayload, useItPoliciesQuery } from '@hofy/api-admin';
import {
    allAdminRoles,
    allEmailTypes,
    allManagerRoles,
    EmailType,
    fromEmailTypes,
    Permission,
    Role,
    useTrRole,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { FormRow } from '@hofy/common';
import { isEmpty, isNotEmpty } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import {
    CheckboxGroup,
    FormContainer,
    FormDateInput,
    FormEmailInput,
    FormInput,
    FormSection,
    FormSelect,
    Paragraph3,
    UseForm,
    useFormObjectField,
} from '@hofy/ui';
import { EmailTypePicker, TeamDot } from '@hofy/ui-domain';

interface UserFormProps {
    organizationId: number;
    form: UseForm<UpdateUserPayload>;
    disableManagerRoles?: boolean;
}

const allManagerAndTeamMemberRoles = [Role.TeamMember, ...allManagerRoles];

export const UserForm: FC<UserFormProps> = ({ organizationId, form, disableManagerRoles = false }) => {
    const { itPolicies, isItPoliciesLoading } = useItPoliciesQuery(organizationId);

    const onChangeEmailTypeValue = (values: EmailType[]) => {
        form.setValues(fromEmailTypes(values));
    };

    useEffect(() => {
        if (form.values.workEmailLogin && isEmpty(form.values.workEmail)) {
            form.setValues({
                workEmailLogin: false,
            });
        }
        if (form.values.personalEmailLogin && isEmpty(form.values.personalEmail)) {
            form.setValues({
                personalEmailLogin: false,
            });
        }
    }, [form.values.workEmail, form.values.personalEmail]);

    const availableEmailTypes = allEmailTypes.filter(
        value =>
            (value === EmailType.Personal && isNotEmpty(form.values.personalEmail)) ||
            (value === EmailType.Work && isNotEmpty(form.values.workEmail)),
    );
    const emailType = allEmailTypes.filter(
        value =>
            (value === EmailType.Personal && form.values.personalEmailLogin) ||
            (value === EmailType.Work && form.values.workEmailLogin),
    );

    const trRole = useTrRole();
    const { hasPermission } = useSession();
    const adminCanChangePermissions = hasPermission(Permission.AdminUsersChangePermissions);
    const adminCanChangeBasicPermissions = hasPermission(Permission.AdminUsersChangeBasicPermissions);

    const address = useFormObjectField(form.fields.address);
    const teamNames = useMemo(() => keyBy(itPolicies, t => t.id), [itPolicies]);

    return (
        <FormContainer marginVertical={20}>
            <FormSection label='Details'>
                <FormSelect
                    label='Team'
                    isRequired
                    api={form.fields.itPolicyId}
                    options={itPolicies.map(v => v.id)}
                    disabled={isItPoliciesLoading}
                    toText={id => teamNames[id]?.name}
                    toLabel={id => (
                        <>
                            <TeamDot color={teamNames[id]?.color} />
                            <Paragraph3
                                flex='auto'
                                textNoWrap
                                overflow='hidden'
                                ellipsis
                                color={Color.ContentPrimary}
                            >
                                {teamNames[id]?.name}
                            </Paragraph3>
                        </>
                    )}
                    nullable
                />
                <FormRow>
                    <FormInput label='First name' api={form.fields.firstName} isRequired />
                    <FormInput label='Last name' api={form.fields.lastName} isRequired />
                </FormRow>
                <FormEmailInput
                    label='Work email'
                    api={form.fields.workEmail}
                    isRequired={isEmpty(form.values.personalEmail)}
                    nullable
                />
                <FormEmailInput
                    label='Personal email'
                    api={form.fields.personalEmail}
                    isRequired={isEmpty(form.values.workEmail)}
                    nullable
                />
                <FormSection label='Login email'>
                    <EmailTypePicker
                        disabled={difference(allEmailTypes, availableEmailTypes)}
                        value={emailType}
                        onChange={value => onChangeEmailTypeValue(value)}
                    />
                </FormSection>
                <FormDateInput label='Start date' api={form.fields.startsOn} nullable />
            </FormSection>
            <AddressField api={address} label='Localization' />
            {(adminCanChangePermissions || adminCanChangeBasicPermissions) && (
                <>
                    <FormSection label='Manager roles'>
                        <CheckboxGroup
                            options={allManagerAndTeamMemberRoles}
                            disabledOptions={
                                disableManagerRoles
                                    ? difference(allManagerRoles, form.values.roles)
                                    : undefined
                            }
                            value={form.values.roles}
                            onChange={roles =>
                                form.setValues({
                                    ...form.values,
                                    roles,
                                })
                            }
                            toLabel={trRole}
                            toKey={role => role}
                        />
                    </FormSection>

                    <FormSection label='Admin roles'>
                        <CheckboxGroup
                            options={allAdminRoles}
                            toKey={role => role}
                            toLabel={trRole}
                            onChange={roles =>
                                form.setValues({
                                    ...form.values,
                                    roles,
                                })
                            }
                            value={form.values.roles}
                            disabled={!adminCanChangePermissions}
                        />
                    </FormSection>

                    {form.errors.roles && (
                        <Paragraph3 color={Color.ContentNegative}>{form.errors.roles}</Paragraph3>
                    )}
                </>
            )}
        </FormContainer>
    );
};
