import React, { FC } from 'react';

import { ProductDto } from '@hofy/api-admin';
import { SelectableList } from '@hofy/common';

import { ProductRow } from './ProductRow';

interface ProductPickerProps {
    products: ProductDto[];
    selected?: ProductDto;
    onPick(v: ProductDto): void;
}

export const ProductPicker: FC<ProductPickerProps> = ({ products, onPick, selected }) => {
    return (
        <SelectableList flex='auto' overflow='auto'>
            {products.map(p => (
                <ProductRow
                    key={p.id}
                    product={p}
                    marginLeft={20}
                    onClick={() => onPick(p)}
                    selected={selected?.id === p.id}
                />
            ))}
        </SelectableList>
    );
};
