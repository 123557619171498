import { RepaymentStatus } from '@hofy/api-shared';

export interface RepaymentPlansFilters {
    organizations: number[];
    billingEntities: number[];
    status: RepaymentStatus[];
    search: string;
    isInvoiced: boolean | null;
    contractId?: number;
}

export const emptyRepaymentPlansFilters: RepaymentPlansFilters = {
    organizations: [],
    billingEntities: [],
    status: [],
    isInvoiced: null,
    search: '',
};
