import { useMemo } from 'react';

import { getCountryBillingEntity, useBillingEntitiesQuery, useHofyWarehouseQuery } from '@hofy/api-admin';

export const useOptionalOrganizationBillingEntities = (organizationId?: number) => {
    const { isLoading, billingEntities } = useBillingEntitiesQuery({ organizationId });

    const optionalOrganizationBillingEntities = useMemo(() => {
        if (billingEntities === undefined) {
            return [];
        }

        return organizationId === undefined
            ? billingEntities.filter(billingEntity => billingEntity.organization === null)
            : billingEntities;
    }, [billingEntities]);

    return {
        isLoading,
        billingEntities: optionalOrganizationBillingEntities,
    };
};

export const useBillingEntityForWarehouseCountry = (
    organizationId: number | null,
    warehouseId: number | null,
) => {
    const { isLoading, billingEntities } = useBillingEntitiesQuery({
        organizationId: organizationId ?? undefined,
    });
    const { data: hofyWarehouse } = useHofyWarehouseQuery(warehouseId);
    return useMemo(() => {
        if (
            isLoading ||
            !organizationId ||
            !hofyWarehouse ||
            !billingEntities ||
            !hofyWarehouse.address?.country
        ) {
            return undefined;
        }
        return getCountryBillingEntity(billingEntities, hofyWarehouse.address.country);
    }, [billingEntities, hofyWarehouse]);
};
