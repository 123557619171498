import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { emailLogService } from '../emailLogService';
import { EmailLogDto } from '../types/EmailLogDto';
import { emailLogOrganizationCacheKey } from './cacheKey';

export const useOrganizationEmailLogsQuery = (organizationId: number) => {
    const { data, isLoading, isError, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: emailLogOrganizationCacheKey(organizationId),
        queryFn: param =>
            emailLogService.listOrganizationEmails(organizationId, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const emails: EmailLogDto[] = useMemo(() => {
        if (data === undefined) {
            return [];
        }

        return data.pages.flatMap(v => v.content);
    }, [data]);

    return {
        emails,
        isLoading,
        isError,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
