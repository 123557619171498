import { useQuery } from '@tanstack/react-query';

import { organizationService } from '@hofy/api-admin';

import { organizationsCacheKey } from './organizationsCacheKey';

export const useOrganizationDetailsQuery = (id: number | undefined) => {
    const { data, isLoading } = useQuery({
        queryKey: [organizationsCacheKey, id],
        queryFn: () => organizationService.getOrganization(id!),
        enabled: !!id,
    });

    return {
        data,
        isLoading,
    };
};
