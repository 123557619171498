import React, { FC } from 'react';

import { useUsersQuery } from '@hofy/api-admin';

import { UsersTable } from '../../usersPage/UsersTable';

interface UsersTabProps {
    organizationId: number;
    onOpenUser(userId: number): void;
    search: string;
}

export const UsersTab: FC<UsersTabProps> = ({ organizationId, onOpenUser, search }) => {
    const { adminUsers, adminUsersIsLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useUsersQuery(
        {
            search: search,
            organizations: [organizationId],
            roles: [],
            redistributableIn: null,
            includeOffboarded: true,
        },
    );

    return (
        <UsersTable
            adminUsers={adminUsers}
            onOpenUser={onOpenUser}
            search={search}
            infinityScroll={{
                hasMore: hasNextPage,
                isLoading: adminUsersIsLoading,
                isLoadingMore: isFetchingNextPage,
                loadMore: fetchNextPage,
            }}
        />
    );
};
