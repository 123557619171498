import { ColorDye } from '@hofy/global';

import { AcquisitionType } from './AcquisitionType';

export enum ContractType {
    Rental = 'rental',
    Purchase = 'purchase',
    Management = 'management',
}

export const storeAndReuseStatusColor: Record<ContractType, ColorDye> = {
    [ContractType.Rental]: 'purple',
    [ContractType.Purchase]: 'blue',
    [ContractType.Management]: 'teal',
};

export const contractTypeToAcquisitionType: Record<ContractType, AcquisitionType | null> = {
    [ContractType.Purchase]: AcquisitionType.Purchase,
    [ContractType.Rental]: AcquisitionType.Rental,
    [ContractType.Management]: null,
};

export const allContractTypes = [ContractType.Rental, ContractType.Purchase, ContractType.Management];
