import { BillingEntityContactDto, BillingEntityStatus } from '@hofy/api-shared';
import { Currency, PageRequest, PageResponse, stringifyUrl } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { AdminBillingEntityFilters } from './types/AdminBillingEntityFilters';
import { BillingEntityDetailsDto, BillingEntityDto } from './types/BillingEntityDto';
import { BillingEntityPayload } from './types/BillingEntityPayload';
import { CollectPaymentsPayload } from './types/CollectPaymentsPayload';
import { ForkBillingEntityPayload } from './types/ForkBillingEntityPayload';

export interface BillingEntitiesFilter {
    organizationId?: number;
    status?: BillingEntityStatus[];
}

interface BillingEntitiesResponse {
    billingEntities: BillingEntityDto[];
}

class BillingEntityService {
    public listBillingEntities = async (
        filters: AdminBillingEntityFilters,
        page: PageRequest,
    ): Promise<PageResponse<BillingEntityDto>> => {
        return restClient.getJson<PageResponse<BillingEntityDto>>(
            stringifyUrl({
                url: `/api/admin/billing-entities/list`,
                query: {
                    ...filters,
                    ...page,
                },
            }),
        );
    };

    public getBillingEntities = async (filters?: BillingEntitiesFilter): Promise<BillingEntityDto[]> => {
        const response = await restClient.getJson<BillingEntitiesResponse>(
            stringifyUrl({
                url: '/api/admin/billing-entities',
                query: {
                    ...filters,
                },
            }),
        );

        return response.billingEntities;
    };

    public getBillingEntity = async (billingEntityId: number): Promise<BillingEntityDetailsDto> => {
        return restClient.getJson<BillingEntityDetailsDto>(`/api/admin/billing-entities/${billingEntityId}`);
    };

    public updateBillingEntityCurrency = async (
        billingEntityId: number,
        currency: Currency,
    ): Promise<void> => {
        return restClient.patchJson(`/api/admin/billing-entities/${billingEntityId}/currency`, {
            currency: currency,
        });
    };

    public forkBillingEntity = async (
        billingEntityId: number,
        payload: ForkBillingEntityPayload,
    ): Promise<number> => {
        return restClient
            .postJson<{
                id: number;
            }>(`/api/admin/billing-entities/${billingEntityId}/fork`, payload)
            .then(r => r.id);
    };

    public accountingSync = async (billingEntityId: number) => {
        return restClient.post(`/api/admin/billing-entities/${billingEntityId}/accounting-sync`);
    };

    public updateCredit = async (billingEntityId: number) => {
        return restClient.post(`/api/admin/billing-entities/${billingEntityId}/update-credit`);
    };

    public syncToNetsuite = async () => {
        return restClient.post(`/api/admin/billing-entities/sync-to-netsuite`);
    };

    public createUpdateBillingEntity = async (payload: BillingEntityPayload): Promise<number> => {
        return restClient
            .postJson<{
                id: number;
            }>(`/api/admin/billing-entities`, payload)
            .then(r => r.id);
    };

    public collectPayments = async (id: number, payload: CollectPaymentsPayload) => {
        return restClient.postJson(`/api/admin/billing-entities/${id}/collect-payments`, {
            from: payload.from,
            to: payload.to,
        });
    };

    public retryMandateVerification = async (billingEntityId: number) => {
        return restClient.post(`/api/admin/billing-entities/${billingEntityId}/retry-mandate-verification`);
    };

    public getBillingEntityInvoiceContacts = async (
        billingEntityId: number,
    ): Promise<BillingEntityContactDto[]> => {
        return restClient.getJson<BillingEntityContactDto[]>(
            `/api/admin/billing-entities/${billingEntityId}/invoice-contacts`,
        );
    };
}

export const billingEntitiesService = new BillingEntityService();
