import React, { FC } from 'react';

import { OrganizationDetailsDto } from '@hofy/api-admin';
import { UploadFile } from '@hofy/common';
import { HelpCenterLink } from '@hofy/config';
import { Color } from '@hofy/theme';
import {
    AsyncButton,
    Box,
    Button,
    FormContainer,
    Icon,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph2,
    Paragraph3,
    SvgIcon,
} from '@hofy/ui';

import { FileTypes } from '../../../store/users/types/FileTypes';
import { useUploadUsers } from '../../../store/users/useUploadUsers';

interface UsersUploadModalProps {
    onClose(): void;
    organization: OrganizationDetailsDto;
}

export const UsersUploadModal: FC<UsersUploadModalProps> = ({ onClose, organization }) => {
    const { form, isLoading } = useUploadUsers(organization.idDeprecated, onClose);

    return (
        <Modal width={540} onClose={onClose}>
            <ModalHeader title='Upload users' />
            <ModalContent>
                <FormContainer>
                    <Box>
                        <Paragraph3 paddingBottom={10}>Upload all your Users to Hofy in one go.</Paragraph3>
                        <Box
                            rounded={8}
                            bg={Color.NonContextualGreySubtle}
                            alignItems='center'
                            padding={8}
                            wrap
                            row
                        >
                            <Icon svg={SvgIcon.InfoCircle} color={Color.NonContextualGreyDefault} size={20} />
                            <Paragraph2 marginLeft={8}>
                                For guidelines and for the bulk upload template.
                            </Paragraph2>
                            <Button marginLeft={4} type='plain' to={HelpCenterLink.UsersUpload}>
                                See here
                            </Button>
                        </Box>
                    </Box>
                    <UploadFile
                        label='Choose files to upload'
                        value={form.values.file}
                        accept={FileTypes.XLSX}
                        note='Accepted file types: .xlsx'
                        onChange={file =>
                            form.setValues({
                                file,
                            })
                        }
                        disabled={isLoading}
                        isLoading={isLoading}
                    />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <Button type='secondary' label='Cancel' leftIcon={SvgIcon.Cross} onClick={onClose} />
                <AsyncButton
                    label='Upload'
                    onClick={form.submit}
                    disabled={!form.isValid || isLoading}
                    isLoading={isLoading}
                    disableCheck
                />
            </ModalFooter>
        </Modal>
    );
};
