import React, { FC } from 'react';

import {
    AddressDto,
    allCollectionReasonsUnrelatedToShipment,
    AssignmentCollectionReason,
    canSendReplacement,
    useTrAssignmentCollectionReason,
} from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import {
    Box,
    ConfirmModal,
    Icon,
    LabeledSelect,
    LabeledSwitch,
    LabeledTextarea,
    Paragraph3,
    SvgIcon,
} from '@hofy/ui';

import { useCollectAssignment } from '../../../store/assignments/useCollectAssignment';
import { SelectAddress } from './SelectAddress';
import { StoreAndReuseSelection } from './StoreAndReuseSelection';

interface AssignmentCollectModalProps {
    isActiveRental: boolean;
    assignmentId: number;
    storeAndReuseAllowed: boolean;

    userAddress: AddressDto;
    deliveryAddress: AddressDto;

    onSuccess(): void;
}

export const AssignmentCollectModal: FC<AssignmentCollectModalProps> = ({
    assignmentId,
    isActiveRental,
    storeAndReuseAllowed,
    userAddress,
    deliveryAddress,
    onSuccess,
}) => {
    const { form, collectToHub, collectToLocalPartner, isLoading } = useCollectAssignment(
        assignmentId,
        isActiveRental,
        deliveryAddress,
        onSuccess,
    );
    const trCollectionReason = useTrAssignmentCollectionReason();

    const isReplacementAvailable = canSendReplacement(form.values.collectionReason);
    return (
        <ConfirmModal
            keyPrefix='collect-item-assignment-modal'
            isLoading={isLoading}
            onClose={onSuccess}
            onConfirm={form.submit}
            closeOnConfirm={false}
            width={userAddress.id === deliveryAddress.id ? 450 : 800}
        >
            <LabeledSelect
                options={allCollectionReasonsUnrelatedToShipment}
                label='Collection Reason'
                toText={trCollectionReason}
                value={form.values.collectionReason}
                onChange={collectionReason => {
                    form.setValues({
                        collectionReason,
                    });
                    if (
                        !(
                            collectionReason === AssignmentCollectionReason.Defective ||
                            collectionReason === AssignmentCollectionReason.Other ||
                            collectionReason === AssignmentCollectionReason.DamagedInTransit
                        )
                    ) {
                        form.setValues({
                            sendReplacement: false,
                        });
                    }
                    if (collectionReason !== AssignmentCollectionReason.Other) {
                        form.setValues({
                            collectionNotes: undefined,
                        });
                    }
                }}
                isRequired
                marginVertical={20}
                errorMessage={form.errors.collectionReason}
            />
            {form.values.collectionReason === AssignmentCollectionReason.Other && (
                <LabeledTextarea
                    marginBottom={10}
                    label='Reason:'
                    value={form.values.collectionNotes}
                    onChange={collectionNotes =>
                        form.setValues({
                            collectionNotes,
                        })
                    }
                    helperText='Max 500 characters'
                    rows={4}
                    errorMessage={form.errors.collectionNotes}
                />
            )}
            <LabeledSwitch
                marginTop={10}
                label='Send replacement item'
                checked={isReplacementAvailable && form.values.sendReplacement}
                onChange={sendReplacement =>
                    form.setValues({
                        sendReplacement,
                    })
                }
                disabled={!isReplacementAvailable}
            />
            {isReplacementAvailable && form.values.sendReplacement && (
                <Box row marginTop={10} marginBottom={10}>
                    <Icon
                        svg={SvgIcon.AlertTriangle}
                        size={20}
                        marginRight={12}
                        color={Color.ContentWarning}
                    />
                    <Paragraph3 color={Color.ContentWarning} marginTop={2}>
                        A replacement order will be created
                    </Paragraph3>
                </Box>
            )}
            {storeAndReuseAllowed && (
                <>
                    <LabeledSwitch
                        marginTop={10}
                        label='Enable store and reuse collection'
                        checked={form.values.isStoreAndReuseCollection}
                        onChange={isStoreAndReuseCollection => {
                            form.setValues({
                                isStoreAndReuseCollection: isStoreAndReuseCollection,
                            });
                            if (!form.values.collectionReason && isStoreAndReuseCollection) {
                                form.setValues({
                                    collectionReason: AssignmentCollectionReason.CollectAndReuse,
                                });
                            }
                        }}
                        disabled={!collectToHub && !collectToLocalPartner}
                    />

                    <StoreAndReuseSelection
                        isActiveRental={isActiveRental}
                        isStoreAndReuseCollection={form.values.isStoreAndReuseCollection}
                        collectToHub={collectToHub}
                        collectToLocalPartner={collectToLocalPartner}
                        form={form}
                    />
                </>
            )}
            {isReplacementAvailable && form.values.sendReplacement && (
                <Box marginTop={16}>
                    <SelectAddress
                        userAddress={userAddress}
                        deliveryAddress={deliveryAddress}
                        onSelect={selectedAddress => {
                            form.setValues({
                                deliveryAddressId: selectedAddress.id,
                            });
                        }}
                    />
                </Box>
            )}
        </ConfirmModal>
    );
};
