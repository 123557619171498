import React, { FC, PropsWithChildren, useState } from 'react';

import { OrganizationDetailsDto } from '@hofy/api-admin';
import {
    allAccessoriesContractEndOptions,
    allDevicesContractEndOptions,
    allOrgDevicesContractExtendOptions,
    ContractEndOption,
    ContractExtendOption,
    ParentProductCategoryGroup,
    PaymentSchema,
    useTrContractEndOption,
    useTrContractExtendOption,
} from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import {
    AsyncButton,
    Box,
    ExpandHeight,
    FormContainer,
    FormSection,
    LabeledRadio,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph3,
    Select,
    Switch,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useUpdateOrganizationContractExpirySettings } from '../../../store/organizations/useUpdateOrganizationContractExpirySettings';

interface ContractDefaultsUpdateModalProps {
    organization: OrganizationDetailsDto;
    onClose(): void;
}

export const ContractDefaultsUpdateModal: FC<ContractDefaultsUpdateModalProps> = ({
    organization,
    onClose,
}) => {
    const { contractExpirySettings } = organization;
    const trContractExtendOption = useTrContractExtendOption();
    const trContractEndOption = useTrContractEndOption();

    const { updateContractExpirySetting, updateIsPending } = useUpdateOrganizationContractExpirySettings(
        organization.idDeprecated,
        onClose,
    );

    const [devicesExtendOptionSelected, setDevicesExtendOptionSelected] = useState<ContractExtendOption>(
        contractExpirySettings.devices.contractExtendOption,
    );
    const [devicesEndOptionSelected, setDevicesEndOptionSelected] = useState<ContractEndOption>(
        contractExpirySettings.devices.contractEndOption,
    );
    const [accessoriesEndOptionSelected, setAccessoriesEndOptionSelected] = useState<ContractEndOption>(
        contractExpirySettings.accessories.contractEndOption,
    );

    const isContinueRentingChecked = devicesExtendOptionSelected !== ContractExtendOption.Not;

    const handleChangeContinueRenting = (isChecked: boolean) => {
        return setDevicesExtendOptionSelected(
            isChecked ? ContractExtendOption.Months48 : ContractExtendOption.Not,
        );
    };

    const handleUpdateContractExpirySetting = () => {
        updateContractExpirySetting({
            contractExpirySettings: [
                {
                    parentProductCategoryGroup: ParentProductCategoryGroup.Devices,
                    contractExtendOption: devicesExtendOptionSelected,
                    contractEndOption: devicesEndOptionSelected,
                    paymentSchema: PaymentSchema.Monthly,
                },
                {
                    parentProductCategoryGroup: ParentProductCategoryGroup.Accessories,
                    contractExtendOption: ContractExtendOption.Not,
                    contractEndOption: accessoriesEndOptionSelected,
                    paymentSchema: null,
                },
            ],
        });
    };

    return (
        <ModalWrapper
            onClose={onClose}
            onSubmit={handleUpdateContractExpirySetting}
            isPending={updateIsPending}
        >
            <FormSection label='Devices'>
                <Box column gap={20} marginLeft={16}>
                    <Box flex={1} row justify='space-between'>
                        <Paragraph3 bold color={Color.ContentPrimary}>
                            Continue renting
                        </Paragraph3>
                        <Switch checked={isContinueRentingChecked} onChange={handleChangeContinueRenting} />
                    </Box>
                    <ExpandHeight>
                        {isContinueRentingChecked && (
                            <Box column gap={8}>
                                <Paragraph3 color={Color.ContentPrimary}>
                                    End contracts when they reach
                                </Paragraph3>
                                <Select
                                    options={allOrgDevicesContractExtendOptions}
                                    toText={trContractExtendOption}
                                    value={devicesExtendOptionSelected}
                                    onChange={setDevicesExtendOptionSelected}
                                />
                            </Box>
                        )}
                    </ExpandHeight>
                    <Paragraph3 bold color={Color.ContentPrimary}>
                        End of rental decision
                    </Paragraph3>
                    <Box column gap={16}>
                        {allDevicesContractEndOptions.map(option => (
                            <LabeledRadio
                                label={trContractEndOption(option)}
                                checked={devicesEndOptionSelected === option}
                                onChange={() => setDevicesEndOptionSelected(option)}
                                key={option}
                            />
                        ))}
                    </Box>
                </Box>
            </FormSection>
            <FormSection label='Accessories'>
                <Box column gap={20} marginLeft={16}>
                    <Paragraph3 bold color={Color.ContentPrimary}>
                        End of rental decision
                    </Paragraph3>
                    <Box column gap={16}>
                        {allAccessoriesContractEndOptions.map(option => (
                            <LabeledRadio
                                label={trContractEndOption(option)}
                                checked={accessoriesEndOptionSelected === option}
                                onChange={() => setAccessoriesEndOptionSelected(option)}
                                key={option}
                            />
                        ))}
                    </Box>
                </Box>
            </FormSection>
        </ModalWrapper>
    );
};

interface ModalWrapperProps extends PropsWithChildren {
    isPending: boolean;
    onClose(): void;
    onSubmit(): void;
}

const ModalWrapper: FC<ModalWrapperProps> = ({ onClose, onSubmit, isPending, children }) => {
    return (
        <Modal onClose={onClose} width={600}>
            <ModalHeader title='Update contract defaults' />
            <ModalContent>
                <FormContainer>{children}</FormContainer>
            </ModalContent>
            <ModalFooter borderTop>
                <CancelButton onClick={onClose} />
                <AsyncButton label='Save' onClick={onSubmit} isLoading={isPending} disableCheck />
            </ModalFooter>
        </Modal>
    );
};
