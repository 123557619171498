import React, { FC } from 'react';

import { useItems } from '@hofy/api-admin';
import {
    allItemStatuses,
    ItemLocation,
    ItemStatus,
    ParentProductCategory,
    productCategoriesHierarchy,
    ProductCategory,
} from '@hofy/api-shared';
import { useFilters } from '@hofy/ui';

import { DevicesTable } from './DevicesTable';
import {
    useCategoryFilterDefinition,
    useItemStatusFilterDefinition,
    useLocationFilterDefinition,
} from './filters';

interface OrganizationDevicesTabProps {
    search: string;
    organizationId: number;
    onEntryClick(id: number): void;
}

interface OrganizationDevicesFilters {
    category: ProductCategory[];
    location: ItemLocation[];
    status: ItemStatus[];
}

export const OrganizationDevicesTab: FC<OrganizationDevicesTabProps> = ({
    search,
    organizationId,
    onEntryClick,
}) => {
    const filters = useFilters<OrganizationDevicesFilters>(
        {
            category: useCategoryFilterDefinition(productCategoriesHierarchy[ParentProductCategory.Devices]),
            location: useLocationFilterDefinition(),
            status: useItemStatusFilterDefinition(allItemStatuses),
        },
        {
            category: [],
            location: [],
            status: [],
        },
    );

    const { items, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useItems({
        search,
        organization: [organizationId],
        category: filters.filterValues.category,
        status: filters.filterValues.status,
        location: filters.filterValues.location,
        ownership: null,
        redistributableIn: null,
        variant: [],
        warehouseId: null,
        grade: [],
        filter: null,
    });

    return (
        <DevicesTable
            items={items}
            infinityScroll={{
                isLoading: isLoading,
                isLoadingMore: isFetchingNextPage,
                hasMore: hasNextPage,
                loadMore: fetchNextPage,
            }}
            filters={filters}
            onEntryClick={onEntryClick}
        />
    );
};
