import { ColorDye, getEnumValues } from '@hofy/global';

export enum PurchaseContractStatus {
    Pending = 'pending',
    Purchased = 'purchased',
    Cancelled = 'cancelled',
}

export const purchaseContractStatusColors: Record<PurchaseContractStatus, ColorDye> = {
    [PurchaseContractStatus.Pending]: 'orange',
    [PurchaseContractStatus.Purchased]: 'green',
    [PurchaseContractStatus.Cancelled]: 'red',
};

export const allPurchaseContractStatuses = getEnumValues<PurchaseContractStatus>(PurchaseContractStatus);
