import React, { FC } from 'react';

import { AuditableModelDeprecatedDto } from '@hofy/api-shared';
import { formatDateTime } from '@hofy/helpers';
import { FormGridRow, FormSection, LabeledText } from '@hofy/ui';

import { ActorLabel } from '../../../views/auditLogsPage/components/ActorLabel';

interface AuditInlineFormRowProps {
    model: AuditableModelDeprecatedDto;
}

export const AuditSection: FC<AuditInlineFormRowProps> = ({ model }) => (
    <FormSection label='Auditing'>
        <FormGridRow columns={4}>
            <LabeledText label='Created by' content={<ActorLabel actor={model.createdBy} />} />
            <LabeledText label='Created at' content={formatDateTime(model.createdAt)} />
            <LabeledText label='Updated by' content={<ActorLabel actor={model.updatedBy} />} />
            <LabeledText label='Updated at' content={formatDateTime(model.updatedAt)} />
        </FormGridRow>
    </FormSection>
);
