import {
    AddonItem,
    AddonRefDto,
    AddonType,
    AddressDto,
    AssignmentDto,
    AuditableModelDeprecatedDto,
    BillingEntityDto,
    ContractEndOption,
    ContractExtendOption,
    ContractType,
    CustomerOwnedStoreAndReuseLocation,
    HofySubsidiaryRef,
    ItemRefDto,
    OrganizationRefDto,
    PaymentSchema,
    PaymentType,
    ProductRefDto,
    PurchaseContractStatus,
    RentalContractStatus,
    RentalContractType,
    RentalTerm,
    StorageAssignmentStatus,
    StoreAndReuseDto,
    TermAndConditionRefDto,
    VariantDto,
    WarehouseRefDto,
} from '@hofy/api-shared';
import {
    Country,
    DateString,
    DateTimeString,
    Multiplier,
    Percent,
    Price,
    PricePair,
    UUID,
} from '@hofy/global';

export type ContractDetailsUnionDto =
    | ContractDetailsDtoWithRental
    | ContractDetailsDtoWithPurchase
    | ContractDetailsWithManagement;

interface ContractDetailsDto extends AuditableModelDeprecatedDto {
    uuid: UUID;
    publicId: string;
    type: ContractType;
    assignments: AssignmentDto[];

    isUnbundled: boolean;
    organization: OrganizationRefDto;
    product: ProductRefDto;
    variant: VariantDto;

    isPartner: boolean;
    budgetConsumption: PricePair;

    termAndCondition: TermAndConditionRefDto;
    rentalTerm: RentalTerm | null;

    paymentType: PaymentType;

    rentalContract: RentalContractDetailsDto | null;
    purchaseContract: PurchaseContractDetailsDto | null;
    managementContract: ManagementContractDetailsDto | null;

    mainItem: ItemRefDto | null;
    salesOrderId: number | null;
}

export interface ContractDetailsDtoWithRental extends ContractDetailsDto {
    type: ContractType.Rental;
    rentalContract: RentalContractDetailsDto;
    purchaseContract: null;
}

export interface ContractDetailsDtoWithPurchase extends ContractDetailsDto {
    type: ContractType.Purchase;
    purchaseContract: PurchaseContractDetailsDto;
    rentalContract: null;
}

export interface ContractDetailsWithManagement extends ContractDetailsDto {
    type: ContractType.Management;
    managementContract: ManagementContractDetailsDto;
}

interface RentalContractDetailsDto {
    status: RentalContractStatus;
    pendingOn: DateString;
    activeOn: DateString | null;
    endedOn: DateString | null;
    cancelledOn: DateString | null;
    rentalContracts: RentalSubContractDto[];
    rolloverPrice: Price | null;
    rentalLength: number;
    contractExtendOption: ContractExtendOption;
    contractEndOption: ContractEndOption;
}

interface PurchaseContractDetailsDto extends AuditableModelDeprecatedDto {
    status: PurchaseContractStatus;
    pendingOn: DateString;
    purchasedOn: DateString | null;
    cancelledOn: DateString | null;

    billingToAddress: AddressDto;
    hofySubsidiary: HofySubsidiaryRef;
    billingEntity: BillingEntityDto;

    countryMultiplier: Multiplier;

    basePrice: Price;
    unitPrice: Price;
    price: Price;
    storefrontFee: Percent;

    addons: ContractAddonDto[];
}

export interface ManagementContractDetailsDto {
    billingToAddress: AddressDto;
    hofySubsidiary: HofySubsidiaryRef;
    billingEntity: BillingEntityDto;
    storageAssignments: StorageAssignmentUnionDto[];
    storageMonthlyFee: Price;
    activationFee: Price;
    collectionLocation: CustomerOwnedStoreAndReuseLocation | null;
    country: Country | null;
}

export type StorageAssignmentUnionDto =
    | StorageAssignmentCollectionPending
    | StorageAssignmentCollectionCancelled
    | StorageAssignmentCollectionWithCourierPending
    | StorageAssignmentAtWarehouse
    | StorageAssignmentDeliveryPending
    | StorageAssignmentDeliveryWithCourier
    | StorageAssignmentDeliveredCourier;

interface StorageAssignmentCollectionPending extends StorageAssignmentDto {
    status: StorageAssignmentStatus.CollectionPending;
    collectionPendingAt: DateTimeString;
    collectionCancelledAt: null;
    collectionWithCourierOn: null;
    atWarehouseOn: null;
    deliveryPendingAt: null;
    deliveryWithCourierOn: null;
    deliveredOn: null;
}

interface StorageAssignmentCollectionCancelled extends StorageAssignmentDto {
    status: StorageAssignmentStatus.CollectionCancelled;
    collectionPendingAt: DateTimeString;
    collectionCancelledAt: DateTimeString;
    atWarehouseOn: null;
    deliveryPendingAt: null;
    deliveryWithCourierOn: null;
    deliveredOn: null;
}

interface StorageAssignmentCollectionWithCourierPending extends StorageAssignmentDto {
    status: StorageAssignmentStatus.CollectionWithCourier;
    collectionPendingAt: DateTimeString;
    collectionWithCourierOn: DateString;
    collectionCancelledAt: null;
    atWarehouseOn: null;
    deliveryPendingAt: null;
    deliveryWithCourierOn: null;
    deliveredOn: null;
}

interface StorageAssignmentAtWarehouse extends StorageAssignmentDto {
    status: StorageAssignmentStatus.AtWarehouse;
    atWarehouseOn: DateString;
    collectionCancelledAt: null;
    deliveryPendingAt: null;
    deliveryWithCourierOn: null;
    deliveredOn: null;
}

interface StorageAssignmentDeliveryPending extends StorageAssignmentDto {
    status: StorageAssignmentStatus.DeliveryPending;
    atWarehouseOn: DateString;
    collectionCancelledAt: null;
    deliveryPendingAt: DateTimeString;
    deliveryWithCourierOn: null;
    deliveredOn: null;
}

interface StorageAssignmentDeliveryWithCourier extends StorageAssignmentDto {
    status: StorageAssignmentStatus.DeliveryWithCourier;
    atWarehouseOn: DateString;
    collectionCancelledAt: null;
    deliveryPendingAt: DateTimeString;
    deliveryWithCourierOn: DateString;
    deliveredOn: null;
}

interface StorageAssignmentDeliveredCourier extends StorageAssignmentDto {
    status: StorageAssignmentStatus.Delivered;
    atWarehouseOn: DateString;
    collectionCancelledAt: null;
    deliveryPendingAt: DateTimeString;
    deliveryWithCourierOn: DateString;
    deliveredOn: DateString;
}

interface StorageAssignmentDto {
    id: number;
    status: StorageAssignmentStatus;
    item: ItemRefDto;
    warehouse: WarehouseRefDto;
    collectionPendingAt: DateTimeString | null;
    collectionWithCourierOn: DateString | null;
    collectionCancelledAt: DateTimeString | null;
    atWarehouseOn: DateString | null;
    deliveryPendingAt: DateTimeString | null;
    deliveryWithCourierOn: DateString | null;
    deliveredOn: DateString | null;
}

export interface ContractAddonDto {
    id: number;
    addonType: AddonType;
    addonItems: AddonItem[];

    price: Price;
    unitPrice: Price;
    totalPrice: Price;
    resellPrice: Price | null;
    discount: Percent;
    paymentSchema: PaymentSchema;
    activeOn: DateString | null;
    endOn: DateString | null;
    addon: AddonRefDto;
}

export interface RentalSubContractDto extends AuditableModelDeprecatedDto {
    uuid: UUID;
    billingToAddress: AddressDto;
    rentalType: RentalContractType;
    rentalLength: number | null;
    paymentSchema: PaymentSchema;
    basePrice: Price;
    unitPrice: Price;
    price: Price;
    resellPrice: Price | null;
    tcv: Price;
    organizationTcv: Price;
    status: RentalContractStatus;

    pendingOn: DateString;
    activeOn: DateString | null;
    rolloverOn: DateString | null;
    endedOn: DateString | null;
    endingOn: DateString | null;
    cancelledOn: DateString | null;
    estimatedEndOfContract: DateString | null;
    remainingMonths: number;
    countryMultiplier: Multiplier;
    storefrontFee: Percent;
    paymentDiscount: Percent;
    customDiscount: Percent;
    cancellationDiscount: Percent;
    discount: Percent;
    discountReason: string | null;
    billingSchedule: BillingScheduleDto[];

    purchaseFee: Price | null;
    cancellationFee: Price | null;
    storeAndReuseFee: Price | null;

    executedContractEndOption: ContractEndOption | null;

    possibleRedistribution: boolean;
    hasReadonlyEntries: boolean;

    isMain: boolean;
    isRedistributableOrReusable: boolean;
    hofySubsidiary: HofySubsidiaryRef;
    billingEntity: BillingEntityDto;

    storeAndReuse: StoreAndReuseDto | null;
    addons: ContractAddonDto[];
}

export interface BillingScheduleDto {
    price: Price;
    invoiceMonths: number;
    invoiceTime: DateString;
    rangeFrom: DateString;
    rangeTo: DateString;
}

export interface ContractDatesAware {
    type: ContractType;
    rentalContract: {
        status: RentalContractStatus;
        pendingOn: DateString;
        activeOn: DateString | null;
        endedOn: DateString | null;
        cancelledOn: DateString | null;
    } | null;
    purchaseContract: {
        status: PurchaseContractStatus;
        pendingOn: DateString;
        purchasedOn: DateString | null;
        cancelledOn: DateString | null;
    } | null;
}

export interface RentalContractDatesAware {
    type: ContractType.Rental;
    rentalContract: {
        status: RentalContractStatus;
        pendingOn: DateString;
        activeOn: DateString | null;
        endedOn: DateString | null;
        cancelledOn: DateString | null;
    };
    purchaseContract: null;
}

interface PurchaseContractDatesAware {
    type: ContractType.Purchase;
    purchaseContract: {
        status: PurchaseContractStatus;
        pendingOn: DateString;
        purchasedOn: DateString | null;
        cancelledOn: DateString | null;
    };
    rentalContract: null;
}

export const getRentalContractStatusDate = (
    contract: RentalContractDatesAware,
    status: RentalContractStatus,
): DateString | null => {
    switch (status) {
        case RentalContractStatus.Pending:
            return contract.rentalContract.pendingOn;
        case RentalContractStatus.Active:
            return contract.rentalContract.activeOn;
        case RentalContractStatus.Cancelled:
            return contract.rentalContract.cancelledOn;
        case RentalContractStatus.Ended:
            return contract.rentalContract.endedOn;
    }
};
export const getPurchaseContractStatusDate = (
    contract: PurchaseContractDatesAware,
    status: PurchaseContractStatus,
): DateString | null => {
    switch (status) {
        case PurchaseContractStatus.Pending:
            return contract.purchaseContract.pendingOn;
        case PurchaseContractStatus.Purchased:
            return contract.purchaseContract.purchasedOn;
        case PurchaseContractStatus.Cancelled:
            return contract.purchaseContract.cancelledOn;
    }
};
export const getContractStatusDate = (contract: ContractDatesAware): DateString | null => {
    switch (contract.type) {
        case ContractType.Rental:
            return getRentalContractStatusDate(
                contract as RentalContractDatesAware,
                contract.rentalContract!.status,
            );
        case ContractType.Purchase:
            return getPurchaseContractStatusDate(
                contract as PurchaseContractDatesAware,
                contract.purchaseContract!.status,
            );
        case ContractType.Management:
            return null;
    }
};
export const getMainSubContract = (contract: ContractDetailsDto | null): RentalSubContractDto | null => {
    if (!contract) {
        return null;
    }
    if (contract.type === ContractType.Rental) {
        return contract.rentalContract?.rentalContracts.find(v => v.isMain) || null;
    }
    return null;
};
