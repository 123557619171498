import React, { FC } from 'react';

import { ItemDto } from '@hofy/api-admin';
import { ItemLocation, ItemStatus, ProductCategory } from '@hofy/api-shared';
import {
    FilterHeaderCell,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Placeholder,
    SvgIllustration,
    UseFiltersResult,
} from '@hofy/ui';
import { ItemLocationCell, ItemOwnershipBadge, ProductCell } from '@hofy/ui-domain';

import { ItemStatusChip } from '../../../components/domain/items/ItemStatusChip';
import { IdentifierCell } from './components/IdentifierCell';

interface InvoicesTabProps {
    infinityScroll: InfiniteScrollConfig;
    items: ItemDto[];
    filters: UseFiltersResult<{
        category: ProductCategory[];
        location: ItemLocation[];
        status: ItemStatus[];
    }>;
    onEntryClick(id: number): void;
}

export const DevicesTable: FC<InvoicesTabProps> = ({ infinityScroll, onEntryClick, items, filters }) => {
    return (
        <InfinityScrollTable
            data={items}
            toKey={item => item.id}
            onRowClick={item => onEntryClick(item.id)}
            infinityScroll={infinityScroll}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.AssetsSearch}
                    title='No devices were found for the current search'
                />
            }
            flex='auto'
            columns={[
                {
                    id: 'id',
                    flexGrow: 1,
                    header: 'Identifier',
                    renderer: item => <IdentifierCell id={item.id} publicId={item.publicId} />,
                },
                {
                    id: 'product',
                    flexGrow: 3,
                    header: <FilterHeaderCell label='Product' filter={filters.filters.category} />,
                    renderer: item => <ProductCell product={item.product} />,
                },
                {
                    id: 'location',
                    width: 150,
                    flexGrow: 1,
                    header: <FilterHeaderCell label='Location' filter={filters.filters.location} />,
                    renderer: item => <ItemLocationCell location={item.location} />,
                },
                {
                    id: 'owner',
                    flexGrow: 1,
                    width: 80,
                    header: 'Owner',
                    renderer: item => <ItemOwnershipBadge ownership={item.ownership} />,
                },
                {
                    id: 'status',
                    flexGrow: 1,
                    width: 80,
                    header: <FilterHeaderCell label='Status' filter={filters.filters.status} />,
                    renderer: item => <ItemStatusChip status={item.status} />,
                },
            ]}
        />
    );
};
