export interface SeedRandomUsersPayload {
    organizationId: number;
    baseEmail: string;
    count: number;
}

export const emptySeedRandomUsersPayload: SeedRandomUsersPayload = {
    organizationId: 0,
    baseEmail: '',
    count: 10,
};
