import { sortBy } from 'lodash';
import React, { FC, ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import { RepairStatusUpdateDto } from '@hofy/api-admin';
import { getRepairStatusLabel } from '@hofy/api-shared';
import { formatDate, parseDateTime } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, Icon, Paragraph3, SvgIcon } from '@hofy/ui';

interface RepairTimelineProps {
    statusUpdates: RepairStatusUpdateDto[];
}

export const RepairTimeline: FC<RepairTimelineProps> = ({ statusUpdates }) => {
    const timelineItems = useMemo(
        () => sortBy([...statusUpdates], item => parseDateTime(item.createdAt!).toUnixInteger()).reverse(),
        [statusUpdates],
    );

    return (
        <Box column gap={10}>
            {timelineItems.map(item => {
                return (
                    <TimelineItem
                        key={item.idDeprecated}
                        icon={SvgIcon.Check}
                        iconBgColor={Color.NonContextualGreenSubtle}
                        iconFgColor={Color.NonContextualGreenDefault}
                    >
                        <RepairTimelineStatusUpdate statusUpdate={item} />
                    </TimelineItem>
                );
            })}
        </Box>
    );
};

interface TimelineItemProps {
    icon: Svg;
    iconBgColor: Color;
    iconFgColor: Color;
    children: ReactNode;
}

const TimelineItem: FC<TimelineItemProps> = ({ icon, iconBgColor, iconFgColor, children }) => {
    return (
        <TimeLineItemWrapper row gap={20} alignItems='flex-start' alignContent='stretch' relative>
            <Box bg={iconBgColor} minWidth={20} rect={20} centered rounded={16}>
                <Icon svg={icon} size={12} color={iconFgColor} />
            </Box>

            <Box flex={1} column gap={4}>
                {children}
            </Box>
        </TimeLineItemWrapper>
    );
};

const TimeLineItemWrapper = styled(Box)`
    :not(:last-child) {
        min-height: 50px;
    }

    :not(:last-child)::before {
        content: '';
        position: absolute;
        top: 30px;
        left: 10px;
        height: calc(100% - 30px);
        width: 1px;
        background-color: ${Color.NonContextualBorderDivider};
    }
`;

interface RepairTimelineStatusUpdateProps {
    statusUpdate: RepairStatusUpdateDto;
}

const RepairTimelineStatusUpdate: FC<RepairTimelineStatusUpdateProps> = ({ statusUpdate }) => {
    return (
        <>
            <Paragraph3 bold>{getRepairStatusLabel(statusUpdate.status)}</Paragraph3>
            <Paragraph3>{formatDate(statusUpdate.updatedOn)}</Paragraph3>
        </>
    );
};
