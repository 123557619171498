import React, { FC, useCallback } from 'react';

import { UserAssignmentDto } from '@hofy/api-admin';
import {
    getFirstActiveRentalContractCountry,
    isParentContainsCategory,
    ParentProductCategory,
    Permission,
    Role,
    ShipmentStatus,
    shipmentStatusNotificationType,
    ShipmentType,
    shipmentTypeAssignmentStatus,
    UserWithCountryRefDto,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { FormCheckbox, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { APP_CONFIG } from '@hofy/config';
import { redistributableTo } from '@hofy/global';
import { Color } from '@hofy/theme';
import {
    Alert,
    AsyncButton,
    Box,
    Button,
    Em,
    FormContainer,
    FormSection,
    Icon,
    InfoTooltipIcon,
    LabeledSwitch,
    Link,
    Paragraph3,
    Strong,
    SvgIcon,
} from '@hofy/ui';

import { AdminListDropdown } from '../../../components/domain/user/AdminListDropdown';
import { FormUserSelect } from '../../../components/domain/user/FormUserSelect';
import { useCreateTransferShipment } from '../../../store/shipments/useCreateShipment';
import { SendCollectionSurveyForm } from '../components/SendCollectionSurveyForm';
import { AssignmentSelection } from './AssignmentSelection';

interface CreateTransferShipmentFormProps {
    userId: number;
    organizationId: number;
    selected: UserAssignmentDto[];
    onSuccess(id: number): void;

    onClose(): void;
}

export const CreateTransferShipmentForm: FC<CreateTransferShipmentFormProps> = ({
    selected,
    onSuccess,
    onClose,
    userId,
    organizationId,
}) => {
    const { hasPermission, hasRole } = useSession();

    const canSendSurveyForm = hasPermission(Permission.AdminCollectionRequestSendSurvey);

    const {
        selection,
        isLoadingMutation,
        form,
        selectionContainsFurniture,
        selectionContainsCustomerOwnedFurniture,
    } = useCreateTransferShipment(selected, onSuccess);
    const redistributableInCountry = getFirstActiveRentalContractCountry(selection.assignments);

    const allAssignAreFurniture = selection.assignments.every(i =>
        isParentContainsCategory(ParentProductCategory.Furniture, i.product.category),
    );
    const isDisposeFurnitureAllowed = selectionContainsFurniture && !selectionContainsCustomerOwnedFurniture;

    const userPredicate = useCallback(
        (user: UserWithCountryRefDto) => {
            return (
                user.isOffBoarded === false &&
                (form.values.allowTransfersBetweenTaxZones ||
                    redistributableTo(redistributableInCountry, user.country))
            );
        },
        [redistributableInCountry, form.values.allowTransfersBetweenTaxZones],
    );

    return (
        <>
            <SlideoutHeader title='Create shipment' />
            <SlideoutContent>
                <Alert
                    description='Creating shipment'
                    type={shipmentStatusNotificationType[ShipmentStatus.Created]}
                    marginVertical={30}
                />
                <FormContainer>
                    <FormSection label='Create transfer shipment'>
                        <AdminListDropdown
                            label='Assigned user'
                            emptyContent='Pick user'
                            value={form.values.assignedUserId}
                            onChange={assignedUserId =>
                                form.setValues({
                                    assignedUserId,
                                })
                            }
                        />
                        <FormUserSelect
                            label='To user'
                            organizationId={organizationId}
                            userPredicate={userPredicate}
                            api={form.fields.toUserId}
                            isRequired
                            nullable
                        />
                        {hasRole(Role.SuperAdmin) && (
                            <LabeledSwitch
                                marginTop={10}
                                label='Allow transfers between different tax zones'
                                checked={form.values.allowTransfersBetweenTaxZones}
                                onChange={allowTransfersBetweenTaxZones =>
                                    form.setValues({
                                        allowTransfersBetweenTaxZones,
                                    })
                                }
                            />
                        )}
                        <Box row alignItems='flex-start' gap={4}>
                            <FormCheckbox
                                checked={form.values.omConsentGiven}
                                onChange={omConsentGiven =>
                                    form.setValues({
                                        omConsentGiven,
                                    })
                                }
                                fontColor={Color.NonContextualRedDefault}
                                isError={form.errors.omConsentGiven}
                            >
                                Tick this box if the OM has confirmed that the users involved have consented
                                to their addresses being shared. {form.errors.omConsentGiven}
                            </FormCheckbox>
                            <InfoTooltipIcon
                                body={
                                    <>
                                        Confirmation text for OM:{' '}
                                        <Em>
                                            If you instruct Hofy to redistribute equipment, note that the{' '}
                                            <Strong>name and address</Strong> of the sender or receiver will
                                            be visible to the other person on the equipment packaging. It is
                                            your responsibility to make sure that the sender and receiver are
                                            aware of this, and have consented. We will ask you to confirm that
                                            you have this consent.
                                            {APP_CONFIG.privacyPolicyUrl && (
                                                <>
                                                    Please see{' '}
                                                    <Link to={APP_CONFIG.privacyPolicyUrl} inline invertColor>
                                                        Hofy’s Customer privacy statement
                                                    </Link>{' '}
                                                    for more information.
                                                </>
                                            )}
                                        </Em>
                                    </>
                                }
                                maxWidth={400}
                                interactive
                            />
                        </Box>
                        {selectionContainsFurniture && (
                            <LabeledSwitch
                                label='Dispose of furniture'
                                disabled={!isDisposeFurnitureAllowed}
                                checked={form.values.disposeFurniture}
                                onChange={disposeFurniture =>
                                    form.setValues({
                                        disposeFurniture,
                                    })
                                }
                            />
                        )}
                        {form.values.disposeFurniture && (
                            <LabeledSwitch
                                marginTop={10}
                                label='Arrange collection to dispose items'
                                checked={form.values.arrangeCollectionForDisposal}
                                onChange={userKeepsFurniture =>
                                    form.setValues({
                                        arrangeCollectionForDisposal: userKeepsFurniture,
                                    })
                                }
                            />
                        )}
                        {form.values.disposeFurniture && (
                            <Box row>
                                <Icon
                                    svg={SvgIcon.AlertTriangle}
                                    size={20}
                                    marginRight={12}
                                    color={Color.NonContextualOrangeDefault}
                                />
                                {form.values.arrangeCollectionForDisposal ? (
                                    <Paragraph3 color={Color.ContentWarning} marginTop={2}>
                                        Furniture items will be collected for disposal while a replacement
                                        order will will be created for the new user
                                    </Paragraph3>
                                ) : (
                                    <Paragraph3 color={Color.ContentWarning} marginTop={2}>
                                        Furniture items will be archived as disposed and a replacement order
                                        will be created for the new user
                                    </Paragraph3>
                                )}
                            </Box>
                        )}
                        {form.values.disposeFurniture && allAssignAreFurniture && (
                            <Box row>
                                <Icon
                                    svg={SvgIcon.InfoCircle}
                                    size={20}
                                    marginRight={12}
                                    color={Color.ContentPrimary}
                                />
                                <Paragraph3 color={Color.ContentPrimary} marginTop={2}>
                                    As there is only furniture on this transfer, only the replacement order
                                    will be created and there will be no transfer shipment
                                </Paragraph3>
                            </Box>
                        )}
                        <LabeledSwitch
                            checked={form.values.generateTransferFees}
                            disabled={selectionContainsCustomerOwnedFurniture}
                            onChange={generateTransferFees =>
                                form.setValues({
                                    generateTransferFees,
                                })
                            }
                            label='Generate transfer fees for each assignment'
                        />
                        {form.values.generateTransferFees && (
                            <Box row>
                                <Icon
                                    svg={SvgIcon.InfoCircle}
                                    size={20}
                                    marginRight={12}
                                    color={Color.ContentPrimary}
                                />
                                <Paragraph3 color={Color.ContentPrimary} marginTop={2}>
                                    Transfer invoices are going to be generated once the shipment is completed
                                </Paragraph3>
                            </Box>
                        )}
                        <AssignmentSelection
                            status={shipmentTypeAssignmentStatus[ShipmentType.Transfer]}
                            selection={selection}
                            userId={userId}
                        />
                    </FormSection>
                    {canSendSurveyForm && <SendCollectionSurveyForm showSendOption form={form} />}
                </FormContainer>
            </SlideoutContent>
            <SlideoutFooter>
                <Button
                    type='ghost'
                    negativeMargin
                    onClick={onClose}
                    label='Close'
                    leftIcon={SvgIcon.Cross}
                />
                <AsyncButton
                    isLoading={isLoadingMutation}
                    label='Create'
                    onClick={form.submit}
                    disableCheck
                />
            </SlideoutFooter>
        </>
    );
};
