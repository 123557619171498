import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

import {
    getEmptyInvoiceEntryAddPayload,
    getMainSubContract,
    invoiceEntriesQueryKey,
    invoiceEntryService,
    useBillingEntityQuery,
    useContractDetailsQuery,
} from '@hofy/api-admin';
import { ContractType } from '@hofy/api-shared';
import { useToast } from '@hofy/ui';

import { useInvoiceEntryForm } from './useInvoiceEntryForm';

export const useCreateInvoiceEntry = (contractId: number | null, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const { contract } = useContractDetailsQuery(contractId);
    const mainRentalContract = getMainSubContract(contract ?? null);

    const mutation = useMutation({
        mutationFn: invoiceEntryService.createInvoiceEntry,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [invoiceEntriesQueryKey] });
            showToast({
                type: 'positive',
                message: 'Invoice entry created',
            });
            onSuccess?.();
        },
    });
    const form = useInvoiceEntryForm(
        {
            ...getEmptyInvoiceEntryAddPayload(),
            contractId: contractId,
        },
        mutation.mutate,
    );

    const { billingEntity } = useBillingEntityQuery(form.form.values.billingEntityId ?? undefined);

    const availableContractTypes = useMemo(() => {
        if (!contract) {
            return [];
        }
        const availableContractTypes: ContractType[] = [];
        if (!form.form.values.contractType) {
            if (contract.managementContract) {
                availableContractTypes.push(ContractType.Management);
            }
            if (mainRentalContract) {
                availableContractTypes.push(ContractType.Rental);
            }
            if (contract.purchaseContract) {
                availableContractTypes.push(ContractType.Purchase);
            }
        }
        return availableContractTypes;
    }, [contract]);

    useEffect(() => {
        if (!contract || availableContractTypes.length === 0) {
            return;
        }
        switch (availableContractTypes[0]) {
            case ContractType.Management:
                form.form.setValues({
                    contractType: availableContractTypes[0],
                    billingEntityId: contract.managementContract!.billingEntity.id,
                    hofySubsidiaryId: contract.managementContract!.hofySubsidiary.id,
                });
                return;
            case ContractType.Rental:
                form.form.setValues({
                    contractType: availableContractTypes[0],
                    billingEntityId: mainRentalContract!.billingEntity.id,
                    hofySubsidiaryId: mainRentalContract!.hofySubsidiary.id,
                });
                return;
            case ContractType.Purchase:
                form.form.setValues({
                    contractType: availableContractTypes[0],
                    billingEntityId: contract.purchaseContract!.billingEntity.id,
                    hofySubsidiaryId: contract.purchaseContract!.hofySubsidiary.id,
                });
                return;
        }
    }, [contract, availableContractTypes]);

    useEffect(() => {
        if (billingEntity) {
            form.changeBillingEntity(billingEntity);
        }
    }, [billingEntity]);

    return {
        form,
        isLoadingMutation: mutation.isPending,
        isError: mutation.isError,
        availableContractTypes,
    };
};
