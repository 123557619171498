import { Country, DateString, UUID } from '@hofy/global';

import { AssignmentCollectionReason } from './AssignmentCollectionReason';
import { AssignmentException } from './AssignmentException';
import { AssignmentStatus } from './AssignmentStatus';
import { AssignmentStatusDatesDto } from './AssignmentStatusDatesDto';
import { AssignmentType } from './AssignmentType';
import { ContractDetailsRefUnionDto } from './ContractDetailsRefDto';
import { ItemRefDto } from './ItemRefDto';
import { ProductDto } from './ProductDto';
import { VariantDto } from './VariantDto';

export interface AssignmentDto {
    id: number;
    uuid: UUID;
    publicId: string;
    status: AssignmentStatus;
    statusDates: AssignmentStatusDatesDto;
    user: AssignmentUserDto;
    item: ItemRefDto | null;

    type: AssignmentType;
    exception: AssignmentException | null;
    collectionReason: AssignmentCollectionReason | null;
    isStoreAndReuseCollection: boolean;
    isStoreAndReuseDelivery: boolean;
    collectionNotBefore: DateString | null;
    product: ProductDto;
    variant: VariantDto;

    collectionShipmentId: number | null;
    deliveryShipmentId: number | null;

    orderPublicId: string | null;
}

export interface AssignmentWithContractDto extends AssignmentDto {
    contract: ContractDetailsRefUnionDto | null;
}

export interface AssignmentUserDto {
    id: number;
    uuid: UUID;
    email: string | null;
    firstName: string;
    lastName: string;
    country: Country;
}

interface AssignmentDatesAware {
    status: AssignmentStatus;
    statusDates: AssignmentStatusDatesDto;
}

export const dateForAssignmentStatus = (v: AssignmentDatesAware, status?: AssignmentStatus) => {
    return v.statusDates[status ?? v.status] ?? null;
};
