import React, { FC, ReactNode } from 'react';

import { AddressField } from '@hofy/address';
import { allBillingEntityPaymentTerms, BillingEntityPaymentTerms, useTrPaymentTerms } from '@hofy/api-shared';
import { CountryMultiFormDropdown } from '@hofy/core';
import { coreCurrencies, isUSA } from '@hofy/global';
import { useTrCurrency } from '@hofy/i18n';
import {
    Alert,
    Box,
    FormCheckbox,
    FormContainer,
    FormGridItem,
    FormGridLayout,
    FormInput,
    FormPriceInput,
    FormSection,
    FormSelect,
    useFormObjectField,
} from '@hofy/ui';

import { UseBillingEntityForm } from '../../../../store/invoicing/billingEntities/useBillingEntityForm';
import { ContactsSection } from './ContactsSection';

interface BillingEntityFormProps {
    billingEntityForm: UseBillingEntityForm;
    billingEntityId?: number;
}

export const BillingEntityForm: FC<BillingEntityFormProps> = ({ billingEntityForm, billingEntityId }) => {
    const { form, contacts } = billingEntityForm;
    const trCurrency = useTrCurrency();
    const address = useFormObjectField(form.fields.billingAddress);
    const trPaymentTerms = useTrPaymentTerms<BillingEntityPaymentTerms>(BillingEntityPaymentTerms);
    const companyNumberLabel = isUSA(form.values.billingAddress.country)
        ? 'Employer Identification Number (EIN)'
        : 'Company number';

    return (
        <Box flex='auto' overflow='auto' paddingVertical={30} paddingHorizontal={40}>
            <FormGridLayout columns={4}>
                <FormSection label='Base details'>
                    <FormInput label='Name' api={form.fields.name} isRequired />
                    <FormInput
                        label='VAT number'
                        api={form.fields.vatNumber}
                        disabled={isUSA(form.values.billingAddress.country)}
                        nullable
                    />
                    <FormInput
                        label={companyNumberLabel}
                        api={form.fields.registeredCompanyNumber}
                        isRequired
                        nullable
                    />
                    <FormSelect
                        options={coreCurrencies}
                        label='Currency'
                        api={form.fields.currency}
                        toText={trCurrency}
                        isRequired
                        disabled={!!billingEntityId}
                    />
                    <FormSelect
                        options={allBillingEntityPaymentTerms}
                        label='Payment terms'
                        api={form.fields.paymentTerms}
                        toText={trPaymentTerms}
                        isRequired
                    />
                    <OrganizationRelated organizationId={form.fields.organizationId.value}>
                        <FormCheckbox api={form.fields.isDefault} label='Is default' />
                        <FormCheckbox api={form.fields.isContractSigned} label='Contract signed' />
                        <CountryMultiFormDropdown
                            label='Invoice countries'
                            onChange={countries =>
                                form.setValues({
                                    countries,
                                })
                            }
                            isError={form.errors.countries}
                            value={form.values.countries}
                        />
                    </OrganizationRelated>
                </FormSection>
                <FormContainer>
                    <AddressField api={address} label='Address' />
                    <OrganizationRelated organizationId={form.fields.organizationId.value}>
                        <FormSection label='Two'>
                            {form.values.twoApiEnabled && (
                                <Alert
                                    type='informative'
                                    description='Two integration is only supported in UK/USA based companies'
                                />
                            )}
                            <FormCheckbox
                                api={form.fields.twoApiEnabled}
                                label='Two integration (API)'
                                onChange={enabled =>
                                    form.setValues({
                                        twoSpreadsheetEnabled: false,
                                        twoRecourseLimit: enabled
                                            ? {
                                                  amount: '1000000.00',
                                                  currency: form.values.currency,
                                              }
                                            : null,
                                    })
                                }
                            />
                            <FormCheckbox
                                api={form.fields.twoSpreadsheetEnabled}
                                label='Two integration (Spreadsheet)'
                                onChange={() =>
                                    form.setValues({
                                        twoApiEnabled: false,
                                        twoRecourseLimit: null,
                                    })
                                }
                            />
                            {form.values.twoApiEnabled && (
                                <FormPriceInput
                                    api={form.fields.twoRecourseLimit}
                                    label='Two recourse limit'
                                    defaultCurrency={form.values.currency}
                                    nullable
                                />
                            )}
                        </FormSection>
                    </OrganizationRelated>
                </FormContainer>
                <FormGridItem columns={2}>
                    <ContactsSection
                        contacts={contacts}
                        twoApiEnabled={form.values.twoApiEnabled}
                        error={
                            form.fields.contacts.shouldShowError()
                                ? form.fields.contacts.errorMessage
                                : undefined
                        }
                    />
                </FormGridItem>
            </FormGridLayout>
        </Box>
    );
};

interface OrganizationRelatedProps {
    organizationId: number | null;
    children?: ReactNode;
}

const OrganizationRelated: FC<OrganizationRelatedProps> = ({ organizationId, children }) => {
    if (!organizationId) {
        return null;
    }
    return <>{children}</>;
};
