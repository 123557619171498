import { useQuery } from '@tanstack/react-query';

import { organizationService, OrganizationTermAndConditionDto } from '@hofy/api-admin';

export const organizationTermAndConditionsCacheKey = 'admin/organization/terms-and-conditions';

const emptyOrganizationTermAndConditions: OrganizationTermAndConditionDto[] = [];
export const useOrganizationTermAndConditionsQuery = (orgId: number) => {
    const { data, isLoading } = useQuery({
        queryKey: [organizationTermAndConditionsCacheKey],

        queryFn: () => organizationService.listOrganizationTermAndConditions(orgId),
    });

    return {
        isLoading,
        data: data || emptyOrganizationTermAndConditions,
    };
};
