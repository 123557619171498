import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { AdminOrganizationsFilters, OrganizationDto, organizationService } from '@hofy/api-admin';

import { organizationsCacheKey } from './organizationsCacheKey';

const emptyOrganizations: OrganizationDto[] = [];
export const useOrganizationsQuery = (filters: AdminOrganizationsFilters) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [
            organizationsCacheKey,
            filters.search,
            filters.statuses,
            filters.currencies,
            filters.platformTiers,
            filters.accountManagers,
            filters.salesReps,
        ],

        queryFn: param =>
            organizationService.listOrganizations(filters, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const organizations: OrganizationDto[] = useMemo(() => {
        if (data === undefined) {
            return [];
        }

        return data.pages.flatMap(v => v.content);
    }, [data]);

    return {
        organizations,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};

export const useOrganizationsRefsQuery = (selectedOrgs: number[], search: string) => {
    const { isLoading, data } = useQuery({
        queryKey: [organizationsCacheKey, selectedOrgs, search],
        queryFn: () => organizationService.getOrganizationRefs(selectedOrgs, search),
    });
    return {
        isLoading,
        data: data || emptyOrganizations,
    };
};
