import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    OrganizationDetailsDto,
    subscriptionCacheKey,
    SubscriptionDto,
    SubscriptionPayload,
    subscriptionService,
} from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

import { useSubscriptionForm } from './useSubscriptionForm';

export const useCreateSubscription = (
    onSuccess: () => void,
    organization: OrganizationDetailsDto,
    defaultSubscription: SubscriptionDto,
) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (payload: SubscriptionPayload) =>
            subscriptionService.createSubscription(organization.idDeprecated, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [subscriptionCacheKey, organization.idDeprecated] });
            showToast({
                type: 'positive',
                message: 'Subscription created',
            });
            onSuccess();
        },
    });

    const form = useSubscriptionForm(mutation.mutate, defaultSubscription);

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
