import { useStructMemo } from '@hofy/hooks';
import { useNumberQueryParam, useStringQueryParam } from '@hofy/router';

interface AdminBillingEntityFilters {
    search: string;
    organizationId: number | null;
}

export const useAdminBillingEntityFilters = () => {
    const [search, setSearch] = useStringQueryParam('search', '');
    const [organizationId, setOrganizationId] = useNumberQueryParam('organizationId');

    const filters = useStructMemo<AdminBillingEntityFilters>({
        search,
        organizationId,
    });

    return {
        filters,
        setSearch,
        setOrganizationId,
    };
};
