import React, { FC } from 'react';

import { ManagementContractDetailsDto } from '@hofy/api-admin';
import { StatusHistory } from '@hofy/common';
import { AnyDateString, getEnumValues } from '@hofy/global';
import { LabeledText } from '@hofy/ui';

enum ManagementContractStatus {
    CollectionPending = 'Collection pending',
    CollectionWithCourier = 'Collection with courier',
    CollectionAtWarehouse = 'Warehouse',
    DeliveryPending = 'Delivery pending',
    DeliveryWithCourier = 'Delivery with courier',
    Delivered = 'With user',
}
export const ManagementContractHistory: FC<{
    managementContract: ManagementContractDetailsDto;
}> = ({ managementContract }) => {
    if (managementContract.storageAssignments.length === 0) {
        return <LabeledText label='Status' content='Collection pending' />;
    }

    const storageAssignmentStatus = (status: ManagementContractStatus): AnyDateString | null => {
        if (managementContract.storageAssignments.length === 0) {
            return null;
        }
        const storageAssignment = managementContract.storageAssignments[0]; //there is only one per management contract
        switch (status) {
            case ManagementContractStatus.CollectionPending:
                return storageAssignment.collectionPendingAt;
            case ManagementContractStatus.CollectionWithCourier:
                return storageAssignment.collectionWithCourierOn;
            case ManagementContractStatus.CollectionAtWarehouse:
                return storageAssignment.atWarehouseOn;
            case ManagementContractStatus.DeliveryPending:
                return storageAssignment.deliveryPendingAt;
            case ManagementContractStatus.DeliveryWithCourier:
                return storageAssignment.deliveryWithCourierOn;
            case ManagementContractStatus.Delivered:
                return storageAssignment.deliveredOn;
        }
    };

    return (
        <StatusHistory
            allStatuses={getEnumValues<ManagementContractStatus>(ManagementContractStatus)}
            dateGetter={storageAssignmentStatus}
            labelFormatter={status => status}
        />
    );
};
