import { useQuery } from '@tanstack/react-query';

import { assignmentService } from '../service/assignmentService';
import { assignmentCacheKey } from './cacheKey';

export const useAssignmentQuery = (id: number) => {
    const { data, isLoading } = useQuery({
        queryKey: assignmentCacheKey(id),
        queryFn: () => assignmentService.getAssignment(id),
    });

    return {
        data,
        isLoading,
    };
};
