export const repairErrorI18n = {
    'api-error.active-repair-already-exist.title': 'Active repair already exists',
    'api-error.active-repair-already-exist.message': 'Cannot perform this operation',
    'api-error.repair-fee-cannot-be-zero.title': 'Repair fee cannot be zero',
    'api-error.repair-fee-cannot-be-zero.message': 'Cannot perform this operation',
    'api-error.repair-fee-cannot-be-negative.title': 'Repair fee cannot be negative',
    'api-error.repair-fee-cannot-be-negative.message': 'Cannot perform this operation',
    'api-error.cannot-bill-customer-owned-without-location.title':
        'Cannot bill an organization-owned item without a contract or a warehouse',
    'api-error.cannot-bill-customer-owned-without-location.message': 'Cannot perform this operation',
    'api-error.cannot-bill-hofy-owned-without-contract.title': 'Cannot bill hofy owned without contract',
    'api-error.cannot-bill-hofy-owned-without-contract.message': 'Cannot perform this operation',
};
