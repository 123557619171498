import { useMutation, useQueryClient } from '@tanstack/react-query';

import { contractsCacheKey, contractService, TermAndConditionDto } from '@hofy/api-admin';
import { useForm, useToast } from '@hofy/ui';

import { UpdateContractsTermAndConditionFormData } from './types/UpdateContractsTermAndConditionFormData';

export const useUpdateContractsTermAndCondition = (
    organizationId: number,
    defaultTermAndCondition: TermAndConditionDto,
    onEnd: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: ({ termAndCondition, ...p }: UpdateContractsTermAndConditionFormData) =>
            contractService.updateContractsTermAndCondition(termAndCondition.id, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [contractsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Contracts successfully updated',
            });
            onEnd();
        },
    });

    const form = useForm<UpdateContractsTermAndConditionFormData>({
        initial: {
            organizationId,
            termAndCondition: defaultTermAndCondition,
            contractsCreatedOnStart: null,
            contractsCreatedOnEnd: null,
        },
        onSubmit: mutation.mutate,
        onDiscard: onEnd,
    });

    return {
        isLoading: mutation.isPending,
        isError: mutation.isError,
        form,
    };
};
