import React, { FC, useState } from 'react';

import { ProductDto, useAssignmentQuery, VariantDto } from '@hofy/api-admin';
import { Slideout, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { Box, Button, Form, SearchInput, SectionTitle3, SubmitButton, SvgIcon } from '@hofy/ui';

import { ProductOverview } from '../../../components/domain/products/ProductOverview';
import {
    ProductAndVariant,
    VariantPicker,
} from '../../../components/domain/products/productPicker/VariantPicker';
import { usePickCustomProduct } from '../../../store/assignments/usePickCustomProduct';
import { useProductsByCategory } from '../../../store/assignments/useProductsByCategory';
import { useProductSearch } from '../../../store/assignments/useProductSearch';

interface PickCustomProductSlideoutProps {
    assignmentId: number;
    onClose(): void;
}

export const PickCustomProductSlideout: FC<PickCustomProductSlideoutProps> = ({ assignmentId, onClose }) => {
    const { data: assignment } = useAssignmentQuery(assignmentId);

    const { isLoading, products } = useProductsByCategory(
        assignment?.product?.category,
        false,
        assignment?.organization.id,
    );
    const [variant, setVariant] = useState<ProductAndVariant>();
    const { products: finalProducts, search, setSearch } = useProductSearch(products);

    const state = usePickCustomProduct(assignmentId, onClose);
    const pickVariant = (v: [ProductDto, VariantDto]) => {
        state.updateCustomVariant({ customVariantId: v[1].id });
    };

    const content = () => {
        if (isLoading || !assignment) {
            return null;
        }
        const product = assignment.product;
        return (
            <>
                {product && (
                    <Box paddingHorizontal={60} borderBottom paddingBottom={20}>
                        <SectionTitle3 paddingVertical={20}>Requested product</SectionTitle3>
                        <ProductOverview imageSize={60} product={product} images={product.image} />
                    </Box>
                )}

                <Box borderBottom paddingBottom={20} flex='auto' column>
                    <SectionTitle3 paddingHorizontal={60} paddingVertical={20}>
                        Possible products
                    </SectionTitle3>
                    <VariantPicker onPick={setVariant} products={finalProducts} selected={variant} />
                </Box>
            </>
        );
    };
    return (
        <Form
            onSubmit={() => pickVariant(variant!)}
            isLoading={state.isLoadingMutation}
            isError={state.isError}
        >
            <Slideout width={600} onClose={onClose}>
                <SlideoutHeader title='Pick custom product' justify='space-between' paddingRight={20}>
                    <SearchInput value={search} onChange={setSearch} placeholder='Search product, SKU…' />
                </SlideoutHeader>
                <Box relative flex='auto' column>
                    {content()}
                </Box>
                <SlideoutFooter>
                    <Button
                        type='ghost'
                        negativeMargin
                        onClick={onClose}
                        label='Cancel'
                        leftIcon={SvgIcon.Cross}
                    />
                    <SubmitButton label='Pick product' disabled={!variant} />
                </SlideoutFooter>
            </Slideout>
        </Form>
    );
};
