import { useMutation } from '@tanstack/react-query';

import { AdminSuppliersFilters, suppliersService, useSuppliersQuery } from '@hofy/api-admin';

export const useSuppliers = (filters: AdminSuppliersFilters) => {
    const { data, isLoading } = useSuppliersQuery(filters);

    const downloadReportMutation = useMutation({
        mutationFn: suppliersService.downloadListSuppliersReport,
    });

    return {
        data,
        isLoading,
        downloadReport: downloadReportMutation.mutate,
    };
};
