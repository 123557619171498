import { filter, includes, map, xor } from 'lodash';
import React, { FC } from 'react';

import { JobContext, useHofySubsidiaryRefs, useJobsSummary } from '@hofy/api-admin';
import {
    allNetsuiteSyncStatuses,
    allSupplierStatuses,
    allSupplierTypes,
    HofySubsidiaryRef,
    netsuiteStatusColors,
    NetsuiteSyncStatus,
    organizationStatusColor,
    Permission,
    SupplierStatus,
    SupplierType,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { ExportButton, Page } from '@hofy/common';
import { useTrCountry } from '@hofy/i18n';
import { Button, FilterChipList, PageHeader, SearchInput, SvgIcon } from '@hofy/ui';

import { BlockFilterButton } from '../../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../../components/design/blockFilters/BlockFilterContainer';
import { EnumMultiBlockFilter } from '../../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../../components/design/blockFilters/hooks/useBlockFilters';
import { ListMultiBlockFilter } from '../../../components/design/blockFilters/ListMultiBlockFilter';
import { JobsSummaryIndicator } from '../../../components/domain/job/JobsSummaryIndicator';
import { useTrNetsuiteSyncStatus } from '../../../store/netsuite/useTrNetsuiteSyncStatus';
import { InventoryTab } from '../../../store/purchaseOrders/types/InventoryTab';
import { useSupplierFilters } from '../../../store/suppliers/useSupplierFilters';
import { useSuppliers } from '../../../store/suppliers/useSuppliers';
import { useTrSupplierStatus } from '../../../store/suppliers/useTrSupplierStatus';
import { useTrSupplierType } from '../../../store/suppliers/useTrSupplierType';
import { InventoryTabs } from '../InventoryTabs';
import { DeliversToBlockFilter } from './components/filters/blocks/DeliversToBlockFilter';
import { SuppliersPageMenu } from './SuppliersPageMenu';
import { SuppliersTable } from './SuppliersTable';

interface SuppliersPageProps {
    onOpenSupplier(id: number): void;
    onNewSupplier(): void;
    onOpenJobs(): void;
}

export const SuppliersPage: FC<SuppliersPageProps> = ({ onOpenSupplier, onNewSupplier, onOpenJobs }) => {
    const {
        search,
        filters,
        filterCount,
        setStatuses,
        setTypes,
        setCountry,
        setSubsidiaries,
        setSearch,
        setNetsuiteStatuses,
    } = useSupplierFilters();

    const { data: jobsSummary } = useJobsSummary([JobContext.Suppliers]);
    const { data: suppliers, isLoading, downloadReport } = useSuppliers(filters);

    const { hofySubsidiaries } = useHofySubsidiaryRefs();
    const toLabel = (id: number) => hofySubsidiaries.find(s => s.id === id)?.name || '';

    const { hasPermission } = useSession();
    const trStatus = useTrSupplierStatus();
    const trType = useTrSupplierType();
    const trCountry = useTrCountry();
    const trNetsuiteStatus = useTrNetsuiteSyncStatus();

    const getSelectedCountry = () => {
        if (filters.country === null) {
            return [];
        }

        return [filters.country];
    };
    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();
    return (
        <Page pageId='suppliers'>
            <PageHeader
                title='Suppliers'
                titleSlot={<JobsSummaryIndicator onClick={onOpenJobs} jobsSummary={jobsSummary} />}
                rightSlot={
                    <>
                        <ExportButton onClick={() => downloadReport(filters)} />
                        <SearchInput
                            value={search}
                            onChange={setSearch}
                            placeholder='Search suppliers…'
                            autoFocus
                        />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                        <Button
                            leftIcon={SvgIcon.Add}
                            label='Add supplier'
                            disabled={!hasPermission(Permission.AdminSuppliersCreate)}
                            onClick={onNewSupplier}
                        />
                        <SuppliersPageMenu />
                    </>
                }
                tabsSlot={<InventoryTabs active={InventoryTab.Suppliers} />}
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <EnumMultiBlockFilter<SupplierStatus>
                    title='Status'
                    selected={filters.statuses}
                    onChange={setStatuses}
                    items={allSupplierStatuses}
                    renderItem={status => trStatus(status)}
                />
                <EnumMultiBlockFilter<SupplierType>
                    title='Type'
                    selected={filters.types}
                    onChange={setTypes}
                    items={allSupplierTypes}
                    renderItem={type => trType(type)}
                />
                <ListMultiBlockFilter
                    title='Subsidiary'
                    selected={filter(hofySubsidiaries, s => includes(filters.subsidiaries, s.id))}
                    items={hofySubsidiaries}
                    onChange={filter => setSubsidiaries(map<HofySubsidiaryRef, number>(filter, s => s.id))}
                    renderItem={s => s?.name || ''}
                />
                <DeliversToBlockFilter selected={filters.country} onChange={setCountry} />
                <EnumMultiBlockFilter<NetsuiteSyncStatus>
                    title='Netsuite sync'
                    icon={SvgIcon.Link}
                    selected={filters.netsuiteStatuses}
                    onChange={setNetsuiteStatuses}
                    items={allNetsuiteSyncStatuses}
                    renderItem={status => trNetsuiteStatus(status)}
                />
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <FilterChipList
                    toKey={status => status}
                    selected={filters.statuses}
                    toLabel={trStatus}
                    onClear={value => setStatuses(xor(filters.statuses, [value]))}
                    color={p => organizationStatusColor[p]}
                />
                <FilterChipList
                    toKey={type => type}
                    selected={filters.types}
                    toLabel={trType}
                    onClear={value => setTypes(xor(filters.types, [value]))}
                    color='teal'
                />
                <FilterChipList
                    toKey={value => value || 'none'}
                    selected={getSelectedCountry()}
                    toLabel={trCountry}
                    onClear={() => setCountry(null)}
                    color='violet'
                />
                <FilterChipList
                    toKey={subsidiary => subsidiary}
                    selected={filters.subsidiaries}
                    toLabel={toLabel}
                    onClear={value => setSubsidiaries(xor(filters.subsidiaries, [value]))}
                    color='violet'
                />
                <FilterChipList
                    toKey={status => status}
                    selected={filters.netsuiteStatuses}
                    toLabel={trNetsuiteStatus}
                    onClear={value => setNetsuiteStatuses(xor(filters.netsuiteStatuses, [value]))}
                    color={p => netsuiteStatusColors[p]}
                />
            </BlockFilterChipContainer>
            <SuppliersTable suppliers={suppliers} isLoading={isLoading} onOpenSupplier={onOpenSupplier} />
        </Page>
    );
};
